import React, { useRef, useState } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepperComponent } from '../../../../_mexablock/assets/ts/components';
import Stepper from '../../../../_mexablock/helpers/components/Stepper';
import RadioButtons from '../../../../_mexablock/helpers/components/form-components/RadioButtons';
import { validatePhone } from '../../../service/catalog/Catalogs';
import AnimationSucces from '../../../../_mexablock/helpers/components/SuccesResponse';
import { changeLada } from '../../../service/operacion/CambioLada';
import Select from 'react-select';
import { useSelectLADAS } from '../hooks/LadaChangeHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18);

const initValues = {
  id: 0,
  searchType: '',
  iccid: null,
  msisdn: null,
  phone: '',
  lada_id: 0,
};

const stepperHeaders = [
  'Validacion',
  'Informacion de linea',
  'Cambio de LADA',
  'Confirmacion del cambio',
  'Completada',
];

const ValidationSchema = [
  Yup.object().shape({
    msisdn: Yup.string()
      .nullable(true)
      .matches(/^\d+$/, 'El SIM debe ser un número')
      .test(
        'msisdn-required',
        'El número MSISDN es requerido',
        function (value) {
          const { iccid } = this.parent;
          // Si no hay ICCID, entonces MSISDN es requerido
          if (!iccid && !value) {
            return this.createError({
              message: 'El número MSISDN es requerido',
            });
          }
          return true;
        }
      ),
    iccid: Yup.string()
      .nullable(true)
      .matches(/^\d+$/, 'El ICCID debe ser un número')
      .test('iccid-required', 'El ICCID es requerido', function (value) {
        const { msisdn } = this.parent;
        // Si no hay MSISDN, entonces ICCID es requerido
        if (!msisdn && !value) {
          return this.createError({ message: 'El ICCID es requerido' });
        }
        return true;
      }),
  }),
  Yup.object().shape({
    lada_id: Yup.number()
      .required('Este campo es requerido')
      .min(1, 'Seleccione una opción'),
  }),
];

const CreateModal: React.FC<Props> = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const stepper = useRef<StepperComponent | null>(null);
  const [clientData, setClientData] = useState<any>(null);
  const [currentSchema, setCurrentSchema] = useState<any>(ValidationSchema[0]);
  const { data: LADAS } = useSelectLADAS(show);
  const [selectedOption, setSelectedOption] = useState(null);
  const [textStepper, setTextStepper] = useState('Continuar');
  const searchTypes = [
    { id: 1, value: 'msisdn', label: 'MSISDN' },
    { id: 2, value: 'iccid', label: 'ICCID' },
  ];
  const validateSimNumber = (simNumber: string) => {
    const simFormatRegex = /^\d{19}$/;

    return simFormatRegex.test(simNumber);
  };

  const formSim = useFormik({
    initialValues: initValues,
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      const curIndex = stepper.current?.currentStepIndex;
      switch (curIndex) {
        case 1:
          validatePhone({
            msisdn: values.msisdn,
            iccid: values.iccid,
          })
            .then((data) => {
              stepper.current?.goNext();
              setCurrentSchema(null);
              setClientData(data.data.doc.data);
            })
            .catch((err) => {
              SimpleAlert(err.response.data.message, 3000, 'error');
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case 2:
          stepper.current?.goNext();
          setCurrentSchema(ValidationSchema[1]);
          setLoading(false);
          break;
        case 3:
          stepper.current?.goNext();
          setCurrentSchema(null);
          setLoading(false);
          break;
        case 4:
          changeLada({
            msisdn: String(clientData?.phone),
            nir: String(values.lada_id),
          })
            .then(() => {
              stepper.current?.goNext();
            })
            .catch((err) => {
              SimpleAlert(
                err.response?.data?.details[0]?.message ??
                  'Ocurrio un error en la operacion',
                3000,
                'error'
              );
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case 5:
          onClose();
          break;
      }
    },
  });

  const handleMsisdn = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formSim.setFieldValue('phone', maskedPhoneNumber);
    formSim.setFieldValue('msisdn', cleanPhoneNumber(maskedPhoneNumber));
  };
  const handleIccidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const numericValue = value.replace(/\D/g, '');

    if (!validateSimNumber(numericValue)) {
      formSim.setFieldError('iccid', 'Formato de ICCID inválido');
    } else {
      formSim.setFieldError('iccid', '');
    }

    formSim.setFieldValue('iccid', numericValue);
  };

  const onClose = () => {
    handleClose(false);
    formSim.resetForm();
    setTextStepper('Continuar');
    setClientData(null);
    setLoading(false);
    setCurrentSchema(ValidationSchema[0]);
    setSelectedOption(null);
  };
  const handleCustomerChange = (selectedOption: any) => {
    const { value } = selectedOption;
    if (value) formSim.setFieldValue('lada_id', value);

    setSelectedOption(selectedOption);
  };

  return (
    <>
      <Modal
        id="kt_modal_create"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered modal-xl"
        show={show}
        onHide={onClose}
      >
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotune/arrows/arr061.svg"
              />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 pt-0">
            <div className="text-center mb-13">
              <h1 className="mb-3">Cambio de LADA</h1>
              <div className="text-muted fw-semibold fs-5">
                Selecciona y agrega el dato correspondiente.
              </div>
            </div>
            <form
              id="kt_modal_generic_form"
              className="form"
              action="#"
              onSubmit={formSim.handleSubmit}
            >
              <Stepper
                headers={stepperHeaders}
                loading={loading}
                onCancel={onClose}
                stepper={stepper}
                textButton={textStepper}
                onBack={(actionBack: any) => {
                  if (actionBack == 1) {
                    setCurrentSchema(null);
                  }
                  if (actionBack == 0) {
                    setCurrentSchema(ValidationSchema[0]);
                  }
                }}
              >
                <div className="w-100">
                  <div className="row g-9 mb-13 justify-content-center">
                    <RadioButtons
                      title="Buscar por:"
                      options={searchTypes}
                      name="searchType"
                      formData={formSim}
                      onChange={(value: any) => {
                        if (value) {
                          if (value == 'msisdn') {
                            formSim.setFieldValue('iccid', '');
                          }

                          if (value == 'iccid') {
                            formSim.setFieldValue('phone', '');
                            formSim.setFieldValue('msisdn', '');
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="row g-9 mb-5 justify-content-center">
                    {formSim.values.searchType === 'msisdn' && (
                      <div className="col-md-3 fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                        <div className="w-100 d-flex justify-content-center">
                          {formSim.touched.msisdn && formSim.errors.msisdn && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">{formSim.errors.msisdn}</span>
                            </div>
                          )}
                        </div>

                        <input
                          className="form-control mb-3"
                          value={formSim.values.phone}
                          placeholder="Ingresa número MSISDN"
                          prefix=""
                          onChange={handleMsisdn}
                        />
                      </div>
                    )}
                    {formSim.values.searchType === 'iccid' && (
                      <div className="col-md-3 fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                        <div className="w-100 d-flex justify-content-center">
                          {formSim.touched.iccid && formSim.errors.iccid && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">{formSim.errors.iccid}</span>
                            </div>
                          )}
                        </div>
                        <input
                          className="form-control mb-3"
                          value={formSim.values.iccid || ''}
                          placeholder="Ingresa número ICCID"
                          prefix=""
                          onChange={handleIccidChange}
                          maxLength={19}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-100 container" style={{ opacity: 0.5 }}>
                  <div className="row">
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2">Nombre</label>
                      </div>
                      <input
                        placeholder="Nombre"
                        disabled
                        className="form-control mb-3 mb-lg-0"
                        type="text"
                        value={clientData?.first_name}
                      />
                    </div>
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2">
                          Apellido Paterno
                        </label>
                      </div>
                      <input
                        placeholder="Apellido Paterno"
                        className="form-control mb-3 mb-lg-0"
                        type="text"
                        disabled
                        value={clientData?.father_last_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2">
                          Apellido Materno
                        </label>
                      </div>
                      <input
                        placeholder="Apellido Materno"
                        disabled
                        className="form-control mb-3 mb-lg-0"
                        type="text"
                        value={clientData?.mother_last_name}
                      />
                    </div>
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Fecha de nacimiento
                        </label>
                      </div>
                      <input
                        type="date"
                        className="form-control mb-3 mb-lg-0"
                        disabled
                        value={clientData?.birthdate.split('T')[0]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2">ICCID</label>
                      </div>
                      <input
                        placeholder="ICCID"
                        disabled
                        className="form-control mb-3 mb-lg-0"
                        type="text"
                        value={clientData?.iccid}
                      />
                    </div>
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2 w-auto">
                          Fecha de activación
                        </label>
                      </div>
                      <input
                        type="date"
                        className="form-control mb-3 mb-lg-0"
                        disabled
                        value={clientData?.created_at.split('T')[0]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2">Numero</label>
                      </div>
                      <input
                        placeholder="Numero"
                        className="form-control mb-3 mb-lg-0"
                        type="text"
                        disabled
                        value={clientData?.phone}
                      />
                    </div>
                    <div className="col mb-7">
                      <div className="w-100 d-flex">
                        <label className="fw-bold fs-6 mb-2">IMEI</label>
                      </div>
                      <input
                        placeholder="IMEI"
                        className="disabled form-control mb-3 mb-lg-0"
                        type="text"
                        disabled
                        value={clientData?.imei}
                      />
                    </div>
                  </div>
                </div>
                {/* Cambio de lada */}
                <div className="w-100 container mt-20 ">
                  <div className="row ">
                    <div className="">
                      <label className="fw-bold fs-6 mb-2 w-auto">NIR</label>
                    </div>
                    <Select
                      options={LADAS}
                      placeholder={'Seleccione'}
                      onChange={handleCustomerChange}
                      value={selectedOption}
                      className="col-md-5 col-sm-5 mb-5"
                      noOptionsMessage={() => {
                        return <span>No hay registros</span>;
                      }}
                      styles={{
                        placeholder: (provided, state) => ({
                          ...provided,
                          color: '#181c32',
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display: 'none',
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: '#7E8199',
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border: '1px solid #e4e6ef',
                          borderRadius: '6px',
                          padding: '3px',
                          fontSize: '1.1rem',
                          boxShadow: 'none',
                          backgroundColor: state.isDisabled
                            ? '#EFF2F5'
                            : 'white',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '1.1rem',
                        }),
                      }}
                    />
                  </div>
                  <div className="col-md-2 col-sm-5 d-flex flex-column align-items-center">
                    <div className="w-100">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Tipo
                      </label>
                    </div>
                    <input
                      className="form-control mb-3 text-center text-muted"
                      value="Movil/MiFi"
                      readOnly
                    />
                  </div>
                </div>
                {/* Fin Cambio de Lada */}
                <h1 className="w-100 text-center mt-20">
                  "¿Está seguro de efectuar este cambio? Este cambio no se puede
                  deshacer."
                </h1>
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <h1 className="text-primary fw-bolder text-center">
                    ¡Cambio de LADA exitoso!
                  </h1>
                  <div className="w-350px rounded mx-auto d-block">
                    <AnimationSucces />
                  </div>
                </div>
              </Stepper>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export { CreateModal };
