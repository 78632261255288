import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { CollapseGraphic } from './CollapseGraphic';
import { formatDate, maskPhoneNumber } from '../../../../_mexablock/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetAll } from '../hooks/DashboardHook';
import { LoadingGrid } from '../../../../_mexablock/helpers';

type Props = {};
let initSales: any = {
  msisdn: '',
  iccid: '',
};

const ConsumosPage: React.FC<Props> = () => {
  const [info, setInfo] = useState({});
  const [loadingView, setLoadingView] = useState(false);
  const [selectedValue, setSelectedValue] = useState('msisdn');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const handleRadioChange = (event: any) => {
    const selected = event.target.value;
    setSelectedValue(selected);
    formik.resetForm({
      values: {
        ...initSales,
        [selected]: '',
      },
    });
    setInfo({});
  };

  const { data, isLoading } = useGetAll(info);
  const radioButtonStyle = (value: any): CSSProperties => ({
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    border: `2px solid ${selectedValue === value ? 'orange' : '#ccc'}`,
    backgroundColor: selectedValue === value ? 'orange' : 'transparent',
    display: 'inline-block',
    marginRight: '0.5rem',
    position: 'relative',
    outline: 'none',
    cursor: 'pointer',
  });

  useEffect(() => {
    if (isLoading) {
      setLoadingView(false);
    }
  }, [isLoading, info]);

  const ConsumoSchema = Yup.object().shape({
    msisdn: Yup.string().when('selection', {
      is: 'msisdn',
      then: Yup.string()
        .matches(/^\d{10}$/, 'MSISDN debe tener 10 dígitos')
        .required('MSISDN es requerido'),
    }),
    iccid: Yup.string().when('selection', {
      is: 'iccid',
      then: Yup.string()
        .matches(/^\d{19}$/, 'ICCID debe tener 19 dígitos')
        .required('ICCID es requerido'),
    }),
  });

  const formik = useFormik({
    validationSchema: ConsumoSchema,
    initialValues: initSales,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (
        (values.msisdn !== 0 &&
          values.msisdn !== '' &&
          values.msisdn !== '0') ||
        (values.iccid !== 0 && values.iccid !== '' && values.iccid !== '0')
      ) {
        setLoadingView(true);
        setIsSubmitting(true);
        setInfo(values);
        formik.resetForm();
        setTimeout(() => {
          setIsSubmitting(false);
        }, 3000);
      }
    },
  });

  const combinedRecharges: any[] = [];

  if (data) {
    data.consumo.forEach((current: any) => {
      const existing = combinedRecharges.find(
        (item) =>
          item.date_init === current.date_init &&
          item.date_end === current.date_end
      );

      if (existing) {
        existing.combinedData.push(current);
      } else {
        combinedRecharges.push({
          date_init: current.date_init,
          date_end: current.date_end,
          combinedData: [current],
        });
      }
    });
  }
  return (
    <>
      <div className="card card-custom mb-10">
        <form
          id="kt_search_sales_form"
          className="form"
          action="#"
          onSubmit={formik.handleSubmit}
        >
          <div className="card-header">
            <div className="container">
              <div className="row p-3 mb-3">
                <h3 className="card-title fs-7">Selecciona una opción:</h3>

                <div className="col-md-6 text-left">
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-start align-items-center mt-3">
                      <input
                        placeholder="SIM"
                        className="form-check-input"
                        type="radio"
                        name="selection"
                        value="msisdn"
                        checked={selectedValue === 'msisdn'}
                        onChange={handleRadioChange}
                        style={radioButtonStyle('msisdn')}
                      />
                      <label className="fw-bold fs-7 mb-2 ms-2 w-auto">
                        MSISDN
                      </label>
                    </div>
                    <div className="col-md-6 d-flex justify-content-start align-items-center mt-3">
                      <input
                        placeholder="SIM"
                        className="form-check-input"
                        type="radio"
                        name="selection"
                        value="iccid"
                        checked={selectedValue === 'iccid'}
                        onChange={handleRadioChange}
                        style={radioButtonStyle('iccid')}
                      />
                      <label className="fw-bold fs-7 mb-2 ms-2 w-auto">
                        ICCID
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 text-end">
                  <div className="row ">
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                      <label className="required fw-bold fs-7 me-2 w-auto">
                        Valor
                      </label>
                      <NumericFormat
                        name={selectedValue}
                        className={`form-control ${
                          formik.touched[selectedValue] &&
                          formik.errors[selectedValue]
                            ? 'is-invalid'
                            : ''
                        }`}
                        fixedDecimalScale
                        value={formik.values[selectedValue]}
                        onValueChange={(values) => {
                          const { value } = values;
                          formik.setFieldValue(selectedValue, value);
                        }}
                        maxLength={selectedValue === 'msisdn' ? 10 : 19}
                      />
                      {formik.touched[selectedValue] &&
                        formik.errors[selectedValue] && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formik.errors[selectedValue]}
                            </span>
                          </div>
                        )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                      <button
                        ref={btnRef}
                        type="submit"
                        style={{ backgroundColor: 'orange', color: 'white' }}
                        className={`btn ${
                          isSubmitting
                            ? 'kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light'
                            : ''
                        }`}
                        id="kt_button_1"
                        disabled={isSubmitting}
                      >
                        <i className="bi bi-search fs-5 text-white" />
                        <span className="indicator-label fs-7">
                          {isSubmitting ? 'Espere...' : 'Consultar'}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        {!data ? null : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="fw-bolder text-primary fs-4 mb-5">Titular</div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label className="fw-bolder">Nombre:</label>
                      <div className="fw-bold text-muted">
                        {data.customer
                          ? `${data.customer.first_name} ${data.customer.father_last_name} ${data.customer.mother_last_name}`
                          : 'n/a'}
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="fw-bolder">Fecha Nacimiento:</label>
                      <div className="fw-bold text-muted">
                        {' '}
                        {data.customer?.birthdate
                          ? formatDate(data.customer.birthdate ?? '')
                          : 'n/a'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-1">
                      <label className="fw-bolder">E-mail:</label>
                      <div className="fw-bold text-muted text-truncate">
                        {data.customer ? data.customer.email : 'n/a'}
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="fw-bolder">Empresa/Socio:</label>
                      <div className="fw-bold text-muted">
                        {data.customer
                          ? data.customer.company?.business_name
                          : 'n/a'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-8"
                style={{ borderLeft: '1px solid #f2f3f4' }}
              >
                <div className="fw-bolder text-primary fs-4 mb-5">
                  Información de la línea
                </div>
                <div className="row">
                  <div className="col-md-3">
                    {' '}
                    <div className="mb-1">
                      <label className="fw-bolder">Número:</label>
                      <div className="fw-bold text-muted">
                        {data.customer?.phone
                          ? maskPhoneNumber(String(data.customer.phone) ?? '')
                          : 'n/a'}
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="fw-bolder">ICCID:</label>
                      <div className="fw-bold text-muted">
                        {data.customer?.iccid ? data.customer.iccid : 'n/a'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {' '}
                    <div className="mb-1">
                      <label className="fw-bolder">IMEI:</label>
                      <div className="fw-bold text-muted">
                        {data.customer?.imei ? data.customer.imei : 'n/a'}
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="fw-bolder">Fue portabilidad:</label>
                      <div className="fw-bold text-muted">
                        {data.isPortability ? 'Si' : 'No'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {' '}
                    <div className="mb-1">
                      <label className="fw-bolder">Fecha activación:</label>
                      <div className="fw-bold text-muted">
                        {data.customer?.created_at
                          ? formatDate(data.customer.created_at ?? '')
                          : 'n/a'}
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="fw-bolder">Estado:</label>
                      <div className="fw-bold text-muted">
                        {data.status?.subStatus
                          ? data.status.subStatus === 'Active'
                            ? 'Activo'
                            : 'Inactivo'
                          : 'n/a'}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {' '}
                    <div className="mb-1">
                      <label className="fw-bolder">Razón:</label>
                      <div className="fw-bold text-muted">
                        {data.status
                          ? data.status.reasonCode === ''
                            ? 'n/a'
                            : data.status.reasonCode
                          : 'n/a'}
                      </div>
                    </div>
                    <div className="mb-1">
                      <label className="fw-bolder">Producto:</label>
                      <div className="fw-bold text-muted">
                        {data.customer?.typeService
                          ? data.customer.typeService?.name
                          : 'n/a'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {loadingView ? (
        <LoadingGrid text="Cargando..."></LoadingGrid>
      ) : data && data.consumo && data.consumo.length > 0 ? (
        data.consumo.map((group, index) => (
          <CollapseGraphic
            key={index}
            dataView={group}
            collapse={index === 0}
          />
        ))
      ) : null}
    </>
  );
};

export { ConsumosPage };
