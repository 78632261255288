import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { StepperComponent } from '../../../../../_mexablock/assets/ts/components';
import { useFormik } from 'formik';
import AnimationSucces from '../../../../../_mexablock/helpers/components/SuccesResponse';
import clsx from 'clsx';
import {
  KTSVG,
  toAbsoluteUrl,
  formatPrice,
  // userHasRole,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
} from '../../../../../_mexablock/helpers';
import { useSelectOffers } from '../../activacion/hooks/ActivacionHook';
import { Modal } from 'react-bootstrap-v5';
import {
  validarIMEI,
  validateEmail,
  nuevaActivacion,
  nuevaPortabilidad,
} from '../../../../service/operacion/Validacion';
import { useSelectGeneric } from '../../../catalogos/generico/hooks/genericoHook';
import { getUserByToken } from '../../../../modules/auth/redux/AuthCRUD';
import * as auth from '../../../../modules/auth/redux/AuthRedux';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};
const schemaNewPortability = [
  Yup.object({
    imei: Yup.string()
      .min(15, 'El IMEI debe tener 15 dígitos.')
      .required('Requerido.'),
  }),
  Yup.object({
    first_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    mother_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    father_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    email: Yup.string()
      .email('Formato de correo inválido.')
      .min(3, 'Mínimo 3 caracteres.')
      .max(50, 'Máximo 50 caracteres.')
      .required('Requerido.'),
    birthdate: Yup.date().required('Requerido.'),
    gender_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
  Yup.object({
    iccid: Yup.string()
      .required('Requerido.')
      .min(19, 'Formato de ICCID inválido')
      .max(19, 'Formato de ICCID inválido'),
    offer_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
  Yup.object({
    phoneFormat: Yup.string()
      .required('Requerido.')
      .min(10, 'Formato de teléfono inválido'),
    nip: Yup.string()
      .matches(/^\d{4}$/, 'NIP debe tener 4 dígitos.')
      .required('Requerido.'),
  }),
];

let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 1);

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18);
const fechaMaximaString = fechaMaxima.toISOString().split('T')[0];

const initValues = {
  imei: '',

  first_name: '',
  mother_last_name: '',
  father_last_name: '',
  email: '',
  birthdate: fechaMaxima,
  gender_id: 0,

  iccid: '',
  offer_id: 0,

  phone: '',
  phoneFormat: '',
  nip: '',
  fecha_portabilidad: maxDate.toISOString().split('T')[0],
  activation_id: 0,
};

const PortabilityModal: React.FC<Props> = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  // STEPPER
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(schemaNewPortability[0]);
  const [textStepper, setTextStepper] = useState('Continuar');
  const [loading, setLoading] = useState(false);
  const [portabilityComplete, setPortabilityComplete] = useState<number>(0);
  const { catalog: genders } = useSelectGeneric('GEN');
  const { data: offers } = useSelectOffers(show, 'activation');

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (show) {
      loadStepper();
    }
  }, [stepperRef, show]);

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(
      schemaNewPortability[stepper.current.currentStepIndex - 1]
    );
    setTextStepper('Continuar');
  };

  const formNewPortability = useFormik({
    initialValues: initValues,
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            validarIMEI(values)
              .then((res) => {
                if (!stepper.current) {
                  return;
                }
                const {
                  data: { doc },
                } = res;
                const { homologated } = doc.data.imei;
                if (
                  homologated === 'COMPATIBLE' ||
                  homologated === 'COMPATIBLE HOMOLOGADO' ||
                  homologated === 'COMPATIBLE PROBADO'
                ) {
                  stepper.current.goNext();
                  setCurrentSchema(schemaNewPortability[1]);
                } else {
                  SimpleAlert(
                    'Su dispositivo no es compatible con el servicio.',
                    3000,
                    'error'
                  );
                }
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                SimpleAlert(
                  'Error al validar el IMEI, revisa que el IMEI esté correcto.',
                  3000,
                  'error'
                );
              });
          }, 1000);

          break;

        case 2:
          setLoading(true);
          setTimeout(() => {
            validateEmail(values)
              .then(async (res: any) => {
                if (!stepper.current) {
                  return;
                }
                const { disponible, mensaje } = res.data.doc.data;
                if (disponible) {
                  stepper.current.goNext();
                  setTextStepper('Iniciar portabilidad');
                  setCurrentSchema(schemaNewPortability[2]);
                  setLoading(false);
                } else {
                  SimpleAlert(mensaje, 3000, 'error');
                  setLoading(false);
                }
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;

        case 3:
          setLoading(true);
          setTimeout(() => {
            nuevaActivacion(values)
              .then(async (res: any) => {
                if (!stepper.current) {
                  return;
                }
                stepper.current.goNext();
                setPortabilityComplete(1);

                setCurrentSchema(schemaNewPortability[3]);
                setLoading(false);
                formNewPortability.setFieldValue(
                  'activation_id',
                  res.data.doc.data.activation_id
                );
                SimpleAlert(res.data.message, 3000, 'success');
                // UPDATE SALDO
                const user = await getUserByToken();
                dispatch(auth.actions.fulfillUser(user));
              })
              .catch((err) => {
                SimpleAlert(
                  'Error al validar el ICCID, revisa que el ICCID esté correcto.',
                  3000,
                  'error'
                );
                setLoading(false);
              });
          }, 1000);
          break;
        case 4:
          setLoading(true);
          setTimeout(() => {
            nuevaPortabilidad(values)
              .then((res) => {
                if (!stepper.current) {
                  return;
                }
                stepper.current.goNext();
                setCurrentSchema(schemaNewPortability[4]);
                setLoading(false);
                setTextStepper('Finalizar');
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
                SimpleAlert(resMessageToast, 3000, 'error');
              });
          }, 1000);
          break;
        case 5:
          if (!stepper.current) {
            return;
          }
          handleClose(true);
          stepper.current.goto(1);
          setTextStepper('Finalizar');
          setPortabilityComplete(0);
          setCurrentSchema(schemaNewPortability[0]);
          formNewPortability.resetForm();
          setLoading(false);
          break;
      }
    },
  });

  const getNextAvailableDate = (): string => {
    const today = new Date();
    let nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);

    while (nextDay.getDay() === 0) {
      nextDay.setDate(nextDay.getDate() + 1);
    }

    return nextDay.toISOString().split('T')[0];
  };

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);

    formNewPortability.setFieldValue('phone', maskedPhoneNumber);
    formNewPortability.setFieldValue(
      'phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formNewPortability.setFieldValue(key, inputLimpio);
  };

  const handleDateChange = (e: any) => {
    const fecha = e.target.value
      ? new Date(e.target.value).toISOString().split('T')[0]
      : null;
    formNewPortability.setFieldValue('fecha_portabilidad', fecha);
  };

  return (
    <Modal
      id="kt_modal_portability"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        handleClose(false);
        setTimeout(() => {
          stepper?.current?.goto(1);
          formNewPortability.resetForm();
          setCurrentSchema(schemaNewPortability[0]);
          setTextStepper('Continuar');
          setPortabilityComplete(0);
        }, 1000);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              stepper?.current?.goto(1);
              formNewPortability.resetForm();
              setCurrentSchema(schemaNewPortability[0]);
              setTextStepper('Continuar');
              setPortabilityComplete(0);
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Nueva Portabilidad</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Validación IMEI</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos del Cliente</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos de la Activación</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos para portabilidad</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Completada</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-800px pt-10 pb-10"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formNewPortability.handleSubmit}
            >
              <div
                className="current min-h-550px"
                data-kt-stepper-element="content"
              >
                <div className="row w-100 h-auto">
                  <div className="col-md-6 fv-row  mb-10">
                    <div className="w-100 d-flex">
                      <label className=" fw-bold fs-6 mb-2 w-auto required">
                        IMEI a validar
                      </label>
                      {formNewPortability.touched.imei &&
                        formNewPortability.errors.imei && (
                          <div className="fv-plugins-message-container text-danger ms-5">
                            <span role="alert">
                              {formNewPortability.errors.imei}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="imei"
                      className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                      value={formNewPortability.values.imei}
                      decimalScale={0}
                      decimalSeparator="."
                      maxLength={15}
                      fixedDecimalScale
                      placeholder="IMEI"
                      prefix=""
                      thousandSeparator=""
                      onValueChange={(values) => {
                        const { value } = values;
                        formNewPortability.setFieldValue(
                          'imei',
                          value ? value : ''
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-6 fv-row fv-plugins-icon-container">
                    <div className=" w-100 text-center">
                      <span className="badge badge-light-primary fs-5">
                        Como obtener el IMEI
                      </span>
                    </div>
                    <div className="w-100 text-center">
                      <img
                        alt="GIF"
                        src={toAbsoluteUrl('/media/gif/validacion.gif')}
                        className="h-400px"
                        style={{ borderRadius: '10px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Nombre
                          </label>
                          {formNewPortability.touched.first_name &&
                            formNewPortability.errors.first_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.first_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Nombre"
                          {...formNewPortability.getFieldProps('first_name')}
                          className="form-control mb-3"
                          type="text"
                          name="first_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'first_name');
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Apellido paterno
                          </label>
                          {formNewPortability.touched.father_last_name &&
                            formNewPortability.errors.father_last_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.father_last_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Apellido paterno"
                          {...formNewPortability.getFieldProps(
                            'father_last_name'
                          )}
                          className="form-control mb-3"
                          type="text"
                          name="father_last_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'father_last_name');
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Apellido materno
                          </label>
                          {formNewPortability.touched.mother_last_name &&
                            formNewPortability.errors.mother_last_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.mother_last_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Apellido materno"
                          {...formNewPortability.getFieldProps(
                            'mother_last_name'
                          )}
                          className="form-control mb-3"
                          type="text"
                          name="mother_last_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'mother_last_name');
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Fecha de nacimiento
                          </label>
                          {formNewPortability.touched.birthdate &&
                            formNewPortability.errors.birthdate && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.birthdate}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          onKeyDown={(e) => e.preventDefault()}
                          value={
                            new Date(formNewPortability.values.birthdate)
                              .toISOString()
                              .split('T')[0]
                          }
                          max={fechaMaximaString}
                          onChange={(e) => {
                            if (e.target.value) {
                              const fecha = new Date(e.target.value)
                                .toISOString()
                                .split('T')[0];
                              formNewPortability.setFieldValue(
                                'birthdate',
                                fecha
                              );
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Genero
                          </label>
                          {formNewPortability.touched.gender_id &&
                            formNewPortability.errors.gender_id && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.gender_id}
                                </span>
                              </div>
                            )}
                        </div>
                        <select
                          className="form-select"
                          {...formNewPortability.getFieldProps('gender_id')}
                        >
                          <option value={0}>Seleccione</option>
                          {genders.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Correo
                          </label>
                          {formNewPortability.touched.email &&
                            formNewPortability.errors.email && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewPortability.errors.email}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Correo"
                          {...formNewPortability.getFieldProps('email')}
                          className="form-control mb-3 mb-lg-0"
                          type="text"
                          name="email"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        ICCID
                      </label>
                      {formNewPortability.touched.iccid &&
                        formNewPortability.errors.iccid && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.iccid}
                            </span>
                          </div>
                        )}
                    </div>

                    <NumericFormat
                      className="form-control mb-3"
                      {...formNewPortability.getFieldProps('iccid')}
                      decimalScale={0}
                      maxLength={19}
                      fixedDecimalScale
                      allowNegative={false}
                      placeholder="Identificador (ICCID)"
                      prefix=""
                      onValueChange={(values: any) => {
                        const { floatValue } = values;
                        formNewPortability.setFieldValue(
                          'iccid',
                          floatValue || ''
                        );
                      }}
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Seleciona una oferta
                      </label>
                      {formNewPortability.touched.offer_id &&
                        formNewPortability.errors.offer_id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.offer_id}
                            </span>
                          </div>
                        )}
                    </div>
                    {offers.map((item: any, index: number) => (
                      <a
                        href="#/"
                        key={index}
                        className={clsx(
                          'btn btn-outline btn-flex flex-column flex-stack pb-5 ms-5 mt-5 border-hover-primary',
                          {
                            'btn-active-light-primary active':
                              formNewPortability.values.offer_id === item.id,
                          }
                        )}
                        style={{
                          width: '200px',
                          height: '150px',
                          border: 'solid 1px #dbdfe9',
                        }}
                        onClick={() => {
                          formNewPortability.setFieldValue(`offer_id`, item.id);
                        }}
                      >
                        <div className="">
                          <span
                            className={clsx(
                              ' fw-bold fs-2 d-block text-hover-primary ',
                              {
                                ' text-primary':
                                  formNewPortability.values.offer_id ===
                                  item.id,
                                ' text-gray-800':
                                  formNewPortability.values.offer_id !==
                                  item.id,
                              }
                            )}
                          >
                            {`${item.gb} GB`}
                          </span>
                          <span
                            className={clsx(' fw-semibold fs-5', {
                              ' text-primary':
                                formNewPortability.values.offer_id === item.id,
                              ' text-gray-600':
                                formNewPortability.values.offer_id !== item.id,
                            })}
                          >
                            {`${formatPrice(item.price, 'es-MX', 'MXN', 2, 2)}`}
                          </span>
                          <span
                            className={clsx('  fw-semibold fs-7', {
                              ' text-primary':
                                formNewPortability.values.offer_id === item.id,
                              ' text-gray-500':
                                formNewPortability.values.offer_id !== item.id,
                            })}
                          >
                            {` / ${item.commercial_time}`}
                          </span>
                        </div>

                        <div
                          className={clsx(' fw-semibold fs-6 mt-1', {
                            ' text-primary':
                              formNewPortability.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewPortability.values.offer_id !== item.id,
                          })}
                        >
                          {item.share_internet ? (
                            <KTSVG
                              className="svg-icon-3 svg-icon-success"
                              path="/media/icons/duotune/arrows/arr016.svg"
                            />
                          ) : (
                            <KTSVG
                              className="svg-icon-3"
                              path="/media/icons/duotune/arrows/arr015.svg"
                            />
                          )}
                          Comparte internet
                        </div>
                        <div
                          className={clsx(' fw-semibold fs-6  mt-1', {
                            ' text-primary':
                              formNewPortability.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewPortability.values.offer_id !== item.id,
                          })}
                        >
                          <KTSVG
                            className="svg-icon-3"
                            path="/media/icons/duotune/communication/com013.svg"
                          />
                          {`${item.minutes} Minutos`}
                        </div>
                        <div
                          className={clsx(' fw-semibold fs-6  mt-1', {
                            ' text-primary':
                              formNewPortability.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewPortability.values.offer_id !== item.id,
                          })}
                        >
                          <KTSVG
                            className="svg-icon-3"
                            path="/media/icons/duotune/communication/com003.svg"
                          />
                          {`${item.sms} SMS`}
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              {/* enviar portabilidad */}
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label
                        htmlFor="phoneFormat"
                        className="required fs-6 fw-semibold form-label mb-2"
                      >
                        Número a portar
                      </label>
                      {formNewPortability.touched.phoneFormat &&
                        formNewPortability.errors.phoneFormat && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.phoneFormat}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      id="phoneFormat"
                      className="form-control mb-3"
                      value={formNewPortability.values.phone}
                      placeholder="Número a portar"
                      onChange={handlePhoneChange}
                    />
                    <div className="form-text">
                      Este número será utilizado para iniciar el proceso de
                      portabilidad y transferir tu línea. Asegúrate de que el
                      número esté correctamente escrito y que no contenga
                      errores, ya que este dato es esencial para completar la
                      solicitud de forma exitosa.
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label
                        htmlFor="nip"
                        className="required fs-6 fw-semibold form-label mb-2"
                      >
                        NIP
                      </label>
                      {formNewPortability.touched.nip &&
                        formNewPortability.errors.nip && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewPortability.errors.nip}
                            </span>
                          </div>
                        )}
                    </div>

                    <NumericFormat
                      id="nip"
                      className="form-control mb-3"
                      {...formNewPortability.getFieldProps('nip')}
                      decimalScale={0}
                      fixedDecimalScale
                      placeholder="NIP de portabilidad"
                      prefix=""
                      isAllowed={(values: any) => {
                        const { floatValue = 0 } = values;
                        return floatValue <= 9999;
                      }}
                      onValueChange={(values: any) => {
                        const { floatValue } = values;
                        formNewPortability.setFieldValue('nip', floatValue);
                      }}
                    />
                    <div className="form-text">
                      Para obtener su <span className="fw-bold"> NIP</span> de
                      portabilidad, envie un mensaje al{' '}
                      <span className="fw-bold"> 051</span> con la palabra{' '}
                      <span className="fw-bold"> NIP</span> ó marque al{' '}
                      <span className="fw-bold"> 051</span> y solicite su{' '}
                      <span className="fw-bold"> NIP</span>
                    </div>
                  </div>
                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label
                        htmlFor="fecha_portabilidad"
                        className="required fs-6 fw-semibold form-label mb-2"
                      >
                        Fecha portabilidad
                      </label>
                    </div>
                    <input
                      id="fecha_portabilidad"
                      type="date"
                      className="form-control form-control-solid"
                      value={getNextAvailableDate()}
                      onChange={handleDateChange}
                      disabled
                    ></input>
                    <div className="form-text">
                      Recuerda que la portabilidad no se realizará en domingos
                      ni días festivos. Asimismo, ten en cuenta que el proceso
                      se completará en un plazo máximo de 24 horas.
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="d-flex flex-column justify-content-center align-items-center w-100">
                  <h1 className="text-primary fw-bolder text-center">
                    Se inició la portabilidad con exito!
                  </h1>
                  <div className="w-350px rounded mx-auto d-block">
                    <AnimationSucces />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {portabilityComplete === 0 && (
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Regresar
                    </button>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { PortabilityModal };
