import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  toAbsoluteUrl,
  SimpleAlert,
  LoadingGrid,
} from '../../../helpers';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepperComponent } from '../../../assets/ts/components';
import clsx from 'clsx';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { CommissionModel } from '../../../../app/pages/consumos/models/ConsumosModel';
import { RootState } from '../../../../setup/index';
import { topUpBalance } from '../../../../app/service/operacion/Cuentas';
import { getUserByToken } from '../../../../app/modules/auth/redux/AuthCRUD';
import { getAllCommissions } from '../../../../app/service/commission/commission';
import { useGetAllMethod } from '../../../../app/modules/profile/hooks/profileHook';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import * as auth from '../../../../app/modules/auth/redux/AuthRedux';
import { CreatePaymentMetodModal } from '../../../../app/modules/profile/_modals/CreatePaymentMetodModal';
import AnimationSucces from '../../../helpers/components/SuccesResponse';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};
const schemaBalance = [
  Yup.object({
    new_balance: Yup.number()
      .min(300, 'El saldo debe ser al menos $300.00.')
      .max(5000, 'El saldo no puede exceder $5,000.00.')
      .required('Saldo requerido.'),
  }),
  Yup.object({
    payment_id: Yup.string().required('Método de pago requerido.'),
  }),
  // Yup.object({
  //   password: Yup.string()
  //     .matches(
  //       /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
  //       'Formato de contraseña incorrecta'
  //     )
  //     .required('Contraseña es requerida'),
  // }),
];
const TopUpBalanceModal: React.FC<Props> = ({ show, handleClose }) => {
  const user = useSelector<RootState>((state) => state.auth.user) as UserModel;

  const dispatch = useDispatch();
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commissions, setCommissions] = useState<CommissionModel[]>([]);
  const [loadingView, setloadingView] = useState(true);
  const [readOnlyEfectivo, setReadOnlyEfectivo] = useState<boolean>(true);
  const [readOnlyVirtual, setReadOnlyVirtual] = useState<boolean>(true);
  const [textStepper, setTextStepper] = useState('Continuar');
  const [currentSchema, setCurrentSchema] = useState(schemaBalance[0]);
  const [reloadGrid, setReloadGrid] = useState<number>(0);
  const { data, loadingRequest } = useGetAllMethod(reloadGrid);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [rechargeComplete, setRechargeComplete] = useState<boolean>(true);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (show) {
      loadStepper();
      fetchPost();
      setReloadGrid(Math.random() * 40);
      if (user.account?.type_commision_id === 31) setReadOnlyEfectivo(false);
      if (user.account?.type_commision_id === 32) setReadOnlyVirtual(false);
    }
  }, [stepperRef, show]);

  useEffect(() => {
    if (loadingRequest) {
      setloadingView(false);
    }
  }, [loadingRequest, reloadGrid]);

  const formBalance = useFormik({
    initialValues: {
      virtual_balance: 0,
      new_balance: 0,
      payment_id: '',
      password: '',
    },
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            if (!stepper.current) {
              return;
            }
            stepper.current.goNext();
            setCurrentSchema(schemaBalance[1]);
            setLoading(false);
          }, 1000);

          break;
        case 2:
          setLoading(true);
          setTimeout(() => {
            if (!stepper.current) {
              return;
            }
            stepper.current.goNext();
            // setCurrentSchema(schemaBalance[2]);
            setLoading(false);
            setTextStepper('Recargar');
          }, 1000);
          break;
        case 3:
          setLoading(true);
          setTimeout(() => {
            topUpBalance(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                const {
                  data: { message },
                } = res;
                const user = await getUserByToken();
                dispatch(auth.actions.fulfillUser(user));
                setTextStepper('Finalizar');
                setLoading(false);
                setRechargeComplete(false);
                stepper.current.goNext();
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 2000);
          break;
        case 4:
          if (!stepper.current) {
            return;
          }

          stepper.current.goto(1);
          formBalance.resetForm();
          setTextStepper('Continuar');
          setCurrentSchema(schemaBalance[0]);
          setLoading(false);
          handleClose(true);
          setReadOnlyEfectivo(true);
          setReadOnlyVirtual(true);
          setRechargeComplete(true);
          formBalance.resetForm();

          break;
      }
    },
  });
  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(schemaBalance[stepper.current.currentStepIndex - 1]);
    switch (stepper.current.currentStepIndex - 1) {
      case 1:
        setTextStepper('Continuar');
        break;
      case 2:
        setTextStepper('Continuar');
        break;
    }
  };

  const fetchPost = async () => {
    try {
      const comms = await getAllCommissions({
        type_operation: 'COS',
      });
      if (comms) setCommissions(comms);
    } catch (error) {
      console.error(error);
    }
  };

  const valueToNumber = (value: string): number => {
    const cleanedValue = value.replace(/[^0-9.-]+/g, '');
    return parseFloat(cleanedValue);
  };

  const addRegister = () => {
    setVisivilityModal(!visivilityModal);
  };

  const handleCardSelect = (cardId: string) => {
    setSelectedCardId(cardId);
    formBalance.setFieldValue('payment_id', cardId);
  };

  const onNewBalanceChange = (values: { floatValue: any | null }): void => {
    const { floatValue } = values;
    const newBalance = floatValue ?? 0;

    if (!user || !user.account || !user.account.type_id) {
      console.error(
        'El usuario o la cuenta no están definidos correctamente:',
        user
      );
      formBalance.setFieldValue('new_balance', newBalance);
      formBalance.setFieldValue('virtual_balance', 0);
      return;
    }

    const userCommision = user.account.type_id;
    const userCommissionData = commissions.find(
      (commission) => commission.tipoCuenta.id === userCommision
    );

    if (!userCommissionData) {
      console.error(
        'No se encontró una comisión correspondiente para el usuario'
      );
      formBalance.setFieldValue('new_balance', newBalance);
      formBalance.setFieldValue('virtual_balance', 0);
      return;
    }

    const userPercentage = parseFloat(userCommissionData.percentage);
    const virtualBalance = newBalance * (1 + (userPercentage - 5) / 100);

    formBalance.setFieldValue('new_balance', newBalance);
    formBalance.setFieldValue('virtual_balance', virtualBalance);
  };

  const onNewBalanceChangeVirtual = (values: {
    floatValue: any | null;
  }): void => {
    const { floatValue } = values;
    const newBalance = floatValue ?? 0;

    if (!user || !user.account || !user.account.type_id) {
      console.error(
        'El usuario o la cuenta no están definidos correctamente:',
        user
      );
      formBalance.setFieldValue('new_balance', 0);
      formBalance.setFieldValue('virtual_balance', newBalance);
      return;
    }

    const userCommision = user.account.type_id;
    const userCommissionData = commissions.find(
      (commission) => commission.tipoCuenta.id === userCommision
    );

    if (!userCommissionData) {
      console.error(
        'No se encontró una comisión correspondiente para el usuario'
      );
      formBalance.setFieldValue('new_balance', 0);
      formBalance.setFieldValue('virtual_balance', newBalance);
      return;
    }

    const userPercentage = parseFloat(userCommissionData.percentage);
    const virtualBalance = newBalance * (1 - (userPercentage - 5) / 100);

    formBalance.setFieldValue('new_balance', virtualBalance);
    formBalance.setFieldValue('virtual_balance', newBalance);
  };

  const formatExpiryDate = (expMonth: number, expYear: number): string => {
    const formattedMonth = expMonth.toString().padStart(2, '0');

    const formattedYear = expYear.toString().slice(-2);

    return `${formattedMonth}/${formattedYear}`;
  };
  type CardBrand = 'visa' | 'mastercard' | 'amex';

  const issuers: Record<CardBrand, string> = {
    visa: 'visa',
    mastercard: 'mastercard',
    amex: 'american-express',
  };

  const getIssuer = (brand: string): string => {
    const normalizedBrand = brand.toLowerCase() as CardBrand;

    if (normalizedBrand in issuers) {
      return issuers[normalizedBrand];
    }

    return issuers['visa'];
  };

  return (
    <Modal
      id="kt_modal_top_up_balance"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        stepper?.current?.goto(1);
        formBalance.resetForm();
        setCurrentSchema(schemaBalance[0]);
        setTextStepper('Continuar');
        handleClose(false);
        setSelectedCardId(null);
        setReadOnlyEfectivo(true);
        setReadOnlyVirtual(true);
        setRechargeComplete(true);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              stepper?.current?.goto(1);
              formBalance.resetForm();
              setCurrentSchema(schemaBalance[0]);
              setTextStepper('Continuar');
              handleClose(false);
              setReadOnlyEfectivo(true);
              setReadOnlyVirtual(true);
              setRechargeComplete(true);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body  pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Recarga de saldo</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Saldo a recargar</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Método de pago</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Confirmación</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Transacción exitosa</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-600px pt-10 pb-10 fv-plugins-bootstrap5 fv-plugins-framework"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formBalance.handleSubmit}
            >
              <div className="current" data-kt-stepper-element="content">
                <div className="w-100">
                  <div className="pb-5 pb-lg-10 d-flex justify-content-center align-items-center">
                    <div className="fw-bold fs-6 text-center">
                      *Ingrese la cantidad que desea recargar
                    </div>
                  </div>

                  <div className="mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid d-flex flex-column align-items-center">
                    <div className="w-100 d-flex justify-content-center align-items-center mb-3">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Pesos
                      </label>
                      {formBalance.touched.new_balance &&
                        formBalance.errors.new_balance && (
                          <div className="fv-plugins-message-container text-danger  ms-5">
                            <span role="alert">
                              {formBalance.errors.new_balance}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="new_balance"
                      type="text"
                      autoComplete="off"
                      className="form-control mb-3 w-50 text-center"
                      value={formBalance.values.new_balance}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      allowNegative={false}
                      disabled={readOnlyEfectivo}
                      thousandSeparator=","
                      onChange={
                        readOnlyEfectivo
                          ? undefined
                          : (e) => {
                              onNewBalanceChange({
                                floatValue: valueToNumber(e.target.value) || 0,
                              });
                            }
                      }
                    />
                  </div>
                  <div className="mb-10 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid d-flex flex-column align-items-center">
                    <div className="w-100 d-flex justify-content-center align-items-center mb-3">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Saldo virtual
                      </label>
                    </div>
                    <NumericFormat
                      name="virtual_balance"
                      type="text"
                      autoComplete="off"
                      className="form-control mb-3 w-50 text-center"
                      value={formBalance.values.virtual_balance}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      allowNegative={false}
                      disabled={readOnlyVirtual}
                      thousandSeparator=","
                      onChange={
                        readOnlyVirtual
                          ? undefined
                          : (e) =>
                              onNewBalanceChangeVirtual({
                                floatValue: valueToNumber(e.target.value) || 0,
                              })
                      }
                    />
                  </div>
                </div>
              </div>
              <div data-kt-stepper-element="content" className="pending">
                <div className="w-100">
                  <div className="pb-5 pb-lg-10 ">
                    <h2 className="fw-bold text-center text-dark">
                      Mis métodos de pago
                    </h2>
                    <div className="text-muted fw-semibold fs-6 text-center">
                      Para agregar una nueva tarjeta
                      <a
                        onClick={() => {
                          addRegister();
                        }}
                        className="link-primary fw-bold ms-1"
                      >
                        click aquí
                      </a>
                      .
                    </div>
                  </div>
                  <div className="" data-kt-modal-top-up-wallet-option="dollar">
                    <div className="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                      <div className="w-100 text-center mb-2">
                        <label className="required fw-bold fs-6 mb-2 w-auto">
                          Seleccione un método de pago
                        </label>
                        {formBalance.touched.payment_id &&
                          formBalance.errors.payment_id && (
                            <div className="fv-plugins-message-container text-danger w-50  ms-5">
                              <span role="alert">
                                {formBalance.errors.payment_id}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="row row-cols-1 row-cols-md-2 g-5 overflow-auto">
                        <div className="d-flex flex-nowrap">
                          {loadingView ? (
                            <LoadingGrid text="Cargando..."></LoadingGrid>
                          ) : data &&
                            data.paymentMethods &&
                            data.paymentMethods.length > 0 ? (
                            data.paymentMethods.map(
                              (item: any, index: number) => {
                                const issuer = getIssuer(item.card.brand);
                                const last4 = item.card.last4 || 'XXXX';
                                let number;
                                if (issuer == 'american-express') {
                                  number = `***********${last4}`;
                                } else {
                                  number = `************${last4}`;
                                }
                                const isSelected = selectedCardId === item.id;
                                return (
                                  <div
                                    className="col mb-8 ms-3"
                                    key={index}
                                    onClick={() => handleCardSelect(item.id)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {' '}
                                    <div className="row align-items-center">
                                      <div className="col-10 d-flex align-items-start">
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                            backgroundColor: isSelected
                                              ? 'rgba(100, 149, 237, 0.7)'
                                              : 'none',
                                            boxShadow: isSelected
                                              ? '4px 6px 8px rgba(100, 149, 237, 0.7)'
                                              : 'none',

                                            borderRadius: '8px',
                                            transition:
                                              'box-shadow 0.3s ease, border 0.3s ease',
                                          }}
                                        >
                                          {' '}
                                          <Cards
                                            number={number}
                                            preview={true}
                                            locale={{ valid: 'Valida hasta' }}
                                            issuer={issuer}
                                            expiry={formatExpiryDate(
                                              item.card.exp_month,
                                              item.card.exp_year
                                            )}
                                            cvc=""
                                            name={item.billing_details.name}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <div className="text-muted text-center">
                              Sin Registros
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-kt-stepper-element="content" className="pending">
                <div className="row w-100 justify-content-center align-items-center">
                  <h1 className="fw-bolder text-center">
                    ¿Deseas continuar con la solicitud de esta recarga?
                  </h1>
                </div>
              </div>

              <div data-kt-stepper-element="content" className="pending">
                <div className="row w-100 justify-content-center align-items-center">
                  <h1 className="text-primary fw-bolder text-center">
                    ¡Recarga de saldo realizada exitosamente!
                  </h1>
                  <div className="w-350px rounded mx-auto d-block">
                    <AnimationSucces />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {rechargeComplete && (
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Regresar
                    </button>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CreatePaymentMetodModal
        title="Método de pago"
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            console.log(' modal crear metodo de pago');
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      />
    </Modal>
  );
};
export { TopUpBalanceModal };
