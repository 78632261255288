import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllEnvios(filters?: {
  search?: string;
  status_id?: number;
  start_date?: string;
  end_date?: string;
  activation_status_id?: number;
}) {
  const queryParams = new URLSearchParams();
  if (filters?.search) queryParams.append('search', filters.search);
  if (filters?.status_id)
    queryParams.append('status_id', filters.status_id.toString());
  if (filters?.activation_status_id) {
    queryParams.append(
      'activation_status_id',
      filters.activation_status_id.toString()
    );
  }

  if (filters?.start_date) queryParams.append('start_date', filters.start_date);
  if (filters?.end_date) queryParams.append('end_date', filters.end_date);

  const response = await axios.get(
    `${API_URL}operation/sim-shipments?${queryParams.toString()}`
  );
  const data = response.data?.doc?.data;
  return data;
}

export async function AddNumTracking(body: any) {
  const { trackingNumber, id } = body;

  const response = await axios.patch(
    `${API_URL}operation/sim-shipments/${id}/seguimiento`,
    {
      numero_seguimiento: trackingNumber,
    }
  );

  return response.data;
}

export async function getSelectStatus(filter: string) {
  const response = await axios.get(
    `${API_URL}catalogos/status?filter=${filter}`
  );
  const data = response.data.doc.data.rows;
  let dataSelect: any = data;
  dataSelect.forEach((row: any) => {
    row.label = row.name;
    row.value = row.id;
  });
  return dataSelect;
}

export async function AddComment(body: any) {
  const { status_id, comment, id } = body;
  const response = await axios.post(
    `${API_URL}operation/sim-shipments/${id}/comment/shipment`,
    {
      status_id: status_id,
      comment: comment,
    }
  );

  return response.data;
}
