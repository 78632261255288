import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function validarIMEI(body: any) {
  const { imei } = body;
  const response = await axios.get(`${API_URL}operation/validation/${imei}`);
  return response;
}
export async function validateICCID(body: any) {
  const { iccid } = body;
  const response = await axios.get(
    `${API_URL}operation/validation/iccid/${iccid}`
  );
  return response;
}
export async function validateMSISDN(body: any) {
  const { phoneFormat } = body;
  const response = await axios.post(`${API_URL}users/validateMSISDN/`, {
    phone_number: phoneFormat,
  });
  return response;
}
export async function validateEmail(body: any) {
  const { email } = body;
  const response = await axios.get(
    `${API_URL}users/validation/email/${encodeURIComponent(email)}`
  );
  return response;
}
export async function nuevaActivacion(body: any) {
  const {
    iccid,
    offer_id,
    email,
    first_name,
    father_last_name,
    mother_last_name,
    birthdate,
    gender_id,
    imei,
  } = body;
  const response = await axios.post(`${API_URL}operation/activation/`, {
    iccid: iccid,
    offer_id: Number(offer_id),
    schedule_date: null,
    correo: email,
    nombre: first_name,
    apellido_paterno: father_last_name,
    apellido_materno: mother_last_name,
    fecha_nacimiento: birthdate,
    gender_id: Number(gender_id),
    imei: imei,
    isPortability: true,
  });
  return response;
}
export async function nuevaPortabilidad(body: any) {
  const { activation_id, nip, phoneFormat } = body;
  const response = await axios.post(`${API_URL}operation/portability/`, {
    activation_id: activation_id,
    nip: nip,
    numero_telefono: phoneFormat,
  });
  return response;
}
export async function nuevaPortabilidadAct(body: any) {
  const { nip, phoneFormat, activation_id } = body;
  const response = await axios.post(`${API_URL}operation/portability/`, {
    activation_id: activation_id,
    nip: nip,
    numero_telefono: phoneFormat,
  });
  return response;
}

export async function searchUser(iccid: string) {
  const response = await axios.get(
    `${API_URL}catalogos/activation/iccid/${iccid}`
  );
  return response;
}
