import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../../_mexablock/helpers';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  note: string;
};

const CommentsModal: React.FC<Props> = ({ show, handleClose, note }) => {
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-md"
      show={show}
      //   onHide={() => {
      //     formCallCenter.resetForm();
      //     handleClose(false);
      //   }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              handleClose(true);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="mb-13">
            <h1 className="mb-3"> Comentario </h1>
          </div>

          <div className="alert alert-warning d-flex align-items-center p-5 mb-10">
            <span className="svg-icon svg-icon-2hx svg-icon-warning me-3">
              <KTSVG
                path="/media/icons/duotune/general/gen045.svg"
                className="svg-icon-2tx svg-icon-warning me-4 mt-7"
              />
            </span>

            <div className="d-flex flex-column">
              <span className="text-muted fw-bold">
                {note == null || note == '' ? 'Sin comentarios' : note}
              </span>
            </div>
          </div>
          <div className="text-center pt-10">
            <button
              className="btn btn-primary me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(true);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { CommentsModal };
