import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../_mexablock/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from 'react-select';
import { AddComment, getSelectStatus } from '../../../service/envios/envios';
import { SimpleAlert } from '../../../../_mexablock/helpers';

type OptionType = {
  value: number;
  label: string;
};
type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  infoStatus: any;
};
const StatuSchema = Yup.object().shape({
  status_id: Yup.number()
    .min(1, 'Estatus es requerido.')
    .required('Estatus es requerido.'),
});

const EstatusModal: React.FC<Props> = ({ show, handleClose, infoStatus }) => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [estatusSelectService, setEstatusSelectService] = useState();

  const handleCustomerChange = (selectedOption: any) => {
    const { value } = selectedOption;
    setSelectedOption(selectedOption);
    formStatus.setFieldValue('status_id', value);
  };

  const getTextColor = () => {
    if (selectedOption?.value === 4) return 'red';
    if (selectedOption?.value === 3) return 'green';
    if (selectedOption?.value === 1) return 'orange';
    if (selectedOption?.value === 2) return 'blue';
    return '#181c32';
  };
  const getBackgroundColor = () => {
    if (selectedOption?.value === 4) return '#ffe2e5';
    if (selectedOption?.value === 3) return '#c9f7f5';
    if (selectedOption?.value === 1) return '#fff4de';
    if (selectedOption?.value === 2) return '#f1faff';
    return 'white';
  };
  const statusEnvio = (row: any) => {
    switch (row) {
      case 1:
        return (
          <span className="fw-normal btn btn-light-warning d-inline-block w-100">
            Pendiente
          </span>
        );
      case 2:
        return (
          <span className="fw-normal btn btn-light-primary d-inline-block w-100">
            En proceso
          </span>
        );
      case 3:
        return (
          <span className="fw-normal btn btn-light-success d-inline-block w-100">
            Exitoso
          </span>
        );
      case 5:
        return (
          <span className="fw-normal btn btn-light-warning d-inline-block w-100">
            Por recolectar
          </span>
        );
      case 6:
        return (
          <span className="fw-normal btn btn-light-success d-inline-block w-100">
            Recolectado
          </span>
        );
      case 4:
        return (
          <span className="fw-normal btn btn-light-danger d-inline-block w-100">
            Error
          </span>
        );
    }
  };

  const formStatus = useFormik({
    initialValues: {
      status_id: 0,
      comment: '',
      id: infoStatus.id || 0,
    },
    validationSchema: StatuSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        AddComment(values)
          .then((res) => {
            const { message, doc } = res;
            if (doc?.data) {
              SimpleAlert(message, 3000, 'success');
            } else {
              SimpleAlert(
                'Ocurrió un error inesperado al actualizar el número de seguimiento.',
                3000,
                'error'
              );
            }
            formStatus.resetForm();
            setLoading(false);
            handleClose(true);
            setSelectedOption(null);
          })
          .catch((err) => {
            let resMessageToast = 'Ocurrió un error inesperado';
            if (err.response && err.response.data) {
              const { message, details = null } = err.response.data;
              resMessageToast = details?.length ? details[0].message : message;
            } else {
              console.error('Error sin respuesta:', err);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, 'error');
          });
      }, 1000);
    },
  });

  const fetchPost = async () => {
    try {
      const statusInfo = await getSelectStatus(
        infoStatus.is_pickup ? 'pickup' : 'envio'
      );
      if (statusInfo) setEstatusSelectService(statusInfo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (show) {
      fetchPost();
    }
  }, [show, infoStatus?.is_pickup]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={show}
      onHide={() => {
        handleClose(true);
        formStatus.resetForm();
        setSelectedOption(null);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              handleClose(true);
              formStatus.resetForm();
              setSelectedOption(null);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <form
          id="kt_modal_generic_form"
          className="form"
          action="#"
          onSubmit={formStatus.handleSubmit}
        >
          <div className="modal-body scroll-y mx-5 pt-0">
            <div className="mb-13 text-center">
              <h1 className="mb-3"> Cambio de estatus </h1>
            </div>
            <div className="row text-center mb-10">
              <div className="col">
                <div className="text-start fw-bold mb-2">Actual</div>{' '}
                {infoStatus.status
                  ? statusEnvio(infoStatus.status.id)
                  : 'Sin estatus'}
              </div>
              <div className="col">
                <div
                  className="d-flex justify-content-center align-items-center w-100"
                  style={{ height: '100px' }}
                >
                  <KTSVG
                    className="svg-icon-1"
                    path="/media/icons/duotune/arrows/arr033.svg"
                  />
                </div>
              </div>
              <div className="col">
                <div className="text-start fw-bold mb-2">Nuevo</div>
                <Select
                  options={estatusSelectService}
                  placeholder="Seleccione"
                  onChange={handleCustomerChange}
                  value={selectedOption}
                  className="w-100"
                  noOptionsMessage={() => 'No hay registros'}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      borderRadius: '6px',
                      padding: '3px',
                      fontSize: '1.1rem',
                      boxShadow: 'none',
                      backgroundColor: getBackgroundColor(),
                      color: getTextColor(),
                      borderColor: state.isFocused ? 'transparent' : '#e4e6ef',
                      '&:hover': {
                        borderColor: '#e4e6ef',
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: getTextColor(),
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: '#181c32',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: '1.1rem',
                      backgroundColor: state.isSelected
                        ? getBackgroundColor()
                        : 'white',
                      color: state.isSelected ? getTextColor() : '#181c32',
                    }),
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <div className="text-start mb-2 text-muted">
                <label htmlFor="formComment">Descripción</label>
              </div>
              <textarea
                id="formComment"
                className="form-control rounded-3 mb-5"
                {...formStatus.getFieldProps('comment')}
                rows={4}
                style={{ resize: 'none' }}
                maxLength={500}
              />
            </div>

            <div className="d-flex justify-content-between pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  handleClose(true);
                  formStatus.resetForm();
                  setSelectedOption(null);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Aceptar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export { EstatusModal };
