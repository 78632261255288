import React, { useState, useEffect } from 'react';
import {
  formatPrice,
  LoadingGrid,
  KTSVG,
  formatDateMonth,
} from '../../../../../_mexablock/helpers';
import { AprovisionarModal } from '../_modals/AprovisionarModal';
import { AprovisionamientoModal } from '../_modals/AprovisionarAccionModal';
import { CommentsModal } from '../_modals/ComentariosAprovisionar';
import { ComprobanteModal } from '../_modals/comprobante';
import { TableList } from '../../../../../_mexablock/helpers/components/TableList';
import { useGetAll } from '../hooks/CuentasHook';
import { MenuComponent } from '../../../../../_mexablock/assets/ts/components';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);

  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityModalSupplies, setVisivilityModalSupplies] = useState(false);
  const [visivilityModalComments, setVisivilityModalComments] = useState(false);
  const [visivilityModalComprobante, setVisivilityModalComprobante] =
    useState(false);
  const [voucherModal, setVoucherModal] = useState('');

  const [commetsModal, setcommetsModal] = useState('');
  const [dataSelected, setDataSelected] = useState({
    balance: 0,
    new_balance: 0,
    new_balance2: 0,
    account_number: '',
    company: {},
    distributor: {},
    account_id: 0,
    comments: '',
    comision: '',
  });

  const [dataTipoPago, setDataTipoPago] = useState<any>([]);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);
  useEffect(() => {
    if (loadingRequest > 0) {
      setDataTipoPago(data);
      setloadingView(false);
    }
  }, [loadingRequest]);

  const infoComments = (comment: string) => {
    setReloadGrid(Math.random() * 40);
    setcommetsModal(comment);
    setVisivilityModalComments(true);
  };

  const infoComprobante = (voucher: string) => {
    setReloadGrid(Math.random() * 40);
    setVoucherModal(voucher);
    setVisivilityModalComprobante(true);
  };
  const columns: any = [
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => formatDateMonth(row.created_at),
      sortable: true,
      width: '150px',
    },
    {
      id: 'cash',
      name: <div className="fw-bolder text-muted me-5">Monto (Pesos)</div>,
      selector: (row: any) => row.cash,
      cell: (row: any) => (
        <span className={'text-gray-700 fw-bolder'}>
          {formatPrice(row.cash, 'es-MX', 'MXN', 2, 2)}
        </span>
      ),
      sortable: true,
      width: '150px',
    },
    {
      id: 'virtual',
      name: <div className="fw-bolder text-muted me-5">Saldo (Virtual)</div>,
      selector: (row: any) => row.virtual,
      cell: (row: any) => (
        <span className={'text-gray-700 fw-bolder'}>
          {formatPrice(row.virtual, 'es-MX', 'MXN', 2, 2)}
        </span>
      ),
      sortable: true,
      width: '150px',
    },
    {
      id: 'paymentType.name',
      name: <div className="fw-bolder text-muted me-5">Método de pago</div>,
      selector: (row: any) =>
        row.paymentType ? row.paymentType.name : row.paymentType.name,
      sortable: true,
      width: '150px',
    },
    {
      id: 'to.company.business_name',
      name: <div className="fw-bolder text-muted me-5">Cliente</div>,
      selector: (row: any) => row.to.company.business_name,

      sortable: true,
      width: '150px',
    },
    {
      id: 'to.company.type.name',
      name: <div className="fw-bolder text-muted me-5">Tipo de cliente</div>,
      selector: (row: any) => row.to.company.type.name,

      sortable: true,
      width: '150px',
    },
    {
      id: 'creator.first_name',
      name: (
        <div className="fw-bolder text-muted me-5">Usuario responsable</div>
      ),
      selector: (row: any) => row.creator.first_name,
      cell: (row: any) => (
        <span className="fw-normal">
          {row.creator.first_name} {row.creator.father_last_name}
        </span>
      ),
      sortable: true,
      width: '200px',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '200px',
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  infoComments(row.notas);
                }}
              >
                Ver comentario
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  infoComprobante(row.voucher);
                }}
              >
                Ver comprobante
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const editRegister = (row: any) => {
    setDataSelected({
      balance: row.virtual_balance,
      new_balance: 0,
      new_balance2: 0,
      account_number: row.account_number,
      company: row.company,
      distributor: row.distributor,
      account_id: row.id,
      comments: '',
      comision: row.typeCommission.code,
    });
    setVisivilityModal(!visivilityModal);
  };

  const addSupply = (row: any) => {
    setVisivilityModalSupplies(!visivilityModalSupplies);
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              {/* <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              /> */}
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addSupply}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Aprovicionar
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <AprovisionarModal
        data={dataSelected}
        show={visivilityModal}
        dataPago={dataTipoPago}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setDataSelected({
            balance: 0,
            new_balance: 0,
            new_balance2: 0,
            account_number: '',
            company: {},
            distributor: {},
            account_id: 0,
            comments: '',
            comision: '',
          });
          setVisivilityModal(!visivilityModal);
        }}
      ></AprovisionarModal>
      <AprovisionamientoModal
        show={visivilityModalSupplies}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setDataSelected({
            balance: 0,
            new_balance: 0,
            new_balance2: 0,
            account_number: '',
            company: {},
            distributor: {},
            account_id: 0,
            comments: '',
            comision: '',
          });
          setVisivilityModalSupplies(!visivilityModalSupplies);
        }}
      ></AprovisionamientoModal>

      <CommentsModal
        show={visivilityModalComments}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setVisivilityModalComments(!visivilityModalComments);
          }
        }}
        note={commetsModal}
      />
      <ComprobanteModal
        show={visivilityModalComprobante}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setVisivilityModalComprobante(!visivilityModalComprobante);
          }
        }}
        archivo={voucherModal}
      />
    </>
  );
};

export { View };
