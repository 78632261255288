import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllActivity(year: string) {
  const response = await axios.get(
    `${API_URL}operation/activity/year-totals?year=${year}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getAllIndicators() {
  const response = await axios.get(`${API_URL}operation/activity/current`);
  const data = response.data.doc.data;
  return data;
}

export async function downloadPDF(pdfRoute: string, fileName: string) {
  const date = new Date().toISOString().split('T')[0];

  try {
    const response = await axios({
      url: `${API_URL}${pdfRoute}`,
      method: 'GET',
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 0);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.data) {
        const errorData = await error.response.data.text();
        throw new Error(
          `Error al descargar el PDF: ${
            JSON.parse(errorData).message || 'Desconocido'
          }`
        );
      }
    }
    throw new Error('Error desconocido al descargar el PDF');
  }
}
