import { useEffect } from 'react';
import { useWebSocket } from '../app/context/WebSocketContext';

type SubscriptionOptions<T> = {
  onMessage: (msg: T) => void;
  onError?: (err: any) => void;
};

const useSubscription = <T = any>(channel: string, { onMessage, onError }: SubscriptionOptions<T>) => {
  const { ws, readyState } = useWebSocket();
  useEffect(() => {
    if (!ws || readyState !== WebSocket.OPEN) return;

    const subscribeMessage = JSON.stringify({ channel, action: 'subscribe' });
    const unsubscribeMessage = JSON.stringify({ channel, action: 'unsubscribe' });

    ws.send(subscribeMessage);

    if (onError) {
      ws.addEventListener('error', onError);
    }

    const messageHandler = (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data);
        if (message.channel === channel) {
          onMessage(message.data);
        }
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }
    };

    ws.addEventListener('message', messageHandler);

    return () => {
      ws.send(unsubscribeMessage);
      ws.removeEventListener('message', messageHandler);
      if (onError) {
        ws.removeEventListener('error', onError);
      }
    };
  }, [channel, ws, readyState, onMessage, onError]);
};

export default useSubscription;
