import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getClientSecret() {
  const response = await axios.get(
    `${API_URL}stripe/paymentMethods/setupIntent`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllMethod() {
  const response = await axios.get(`${API_URL}stripe/paymentMethods`);
  const data = response.data.doc.data;
  return data;
}

export async function removePaymentMethods(id: string) {
  const response = await axios.delete(`${API_URL}stripe/paymentMethods/`, {
    data: { paymentId: id },
  });
  return response;
}
