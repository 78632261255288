import { useState, useEffect } from 'react';
import { getMe, getAllMovimientos } from '../../../service/users/Usuarios';
import { MovimientosModel } from '../models/MovimientosModel';
import { useHistory } from 'react-router-dom';
import { getAllMethod } from '../../../service/paymentMethod/PaymentMethod';
import { SimpleAlert } from '../../../../_mexablock/helpers';
import {
  getAllActivity,
  getAllIndicators,
} from '../../../service/activity/activity';
import {
  getAllConsignment,
  getAllIndicatorsConsignment,
} from '../../../service/consignment/consignment';
import {
  getAllBalance,
  getAllIndicatorsBalance,
} from '../../../service/balance/balance';

export const useGetMe = (realoadInfo: boolean, init: any) => {
  const [user, setUser] = useState<any>(init);
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getMe();
      setUser(data);
    };
    fetchPost();
  }, [realoadInfo]);
  return { user };
};
export const useGetAll = (realoadGrid: number) => {
  const [data, setData] = useState<MovimientosModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllMovimientos()
        .then()
        .catch((error) => {
          // history.push('/error/500');
          SimpleAlert('Falta info', 3000, 'error');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetAllMethod = (realoadGrid: number) => {
  const [data, setData] = useState<any>(null);
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllMethod();
        if (res) {
          setData(res);
          setLoadingRequest(true);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { data, loadingRequest };
};
// -------- Tabla de actividad -------------
export const useGetAllActivity = (realoadGrid: number, year: string) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllActivity(year);
        if (res) {
          setData(res);
          setLoadingRequest(1);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { data, loadingRequest };
};
//-------- Termino tabla actividad -----------
export const useGetAllIndicators = (realoadGrid: number) => {
  const [dataIndicators, setDataIndicators] = useState<any>(null);
  const [loadingRequestIndicators, setLoadingRequestIndicators] =
    useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllIndicators();
        if (res) {
          setDataIndicators(res);
          setLoadingRequestIndicators(true);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { dataIndicators, loadingRequestIndicators };
};

// -------- Tabla de saldo -------------
export const useGetAllBalance = (realoadGrid: number, year: string) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllBalance(year);
        if (res) {
          setData(res);
          setLoadingRequest(1);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { data, loadingRequest };
};
//-------- Termino tabla saldo -----------------
//-------- Tabla consignación ------------------
export const useGetAllConsig = (realoadGrid: number, year: string) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllConsignment(year);
        if (res) {
          setData(res);
          setLoadingRequest(1);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { data, loadingRequest };
};
//-------- Termino tabla consignacion ----------
export const useGetAllIndicatorsBalance = (realoadGrid: number) => {
  const [dataIndicators, setDataIndicators] = useState<any>(null);
  const [loadingRequestIndicators, setLoadingRequestIndicators] =
    useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllIndicatorsBalance();
        if (res) {
          setDataIndicators(res);
          setLoadingRequestIndicators(true);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { dataIndicators, loadingRequestIndicators };
};

export const useGetAllIndicatorsConsignment = (realoadGrid: number) => {
  const [dataIndicators, setDataIndicators] = useState<any>(null);
  const [loadingRequestIndicators, setLoadingRequestIndicators] =
    useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await getAllIndicatorsConsignment();
        if (res) {
          setDataIndicators(res);
          setLoadingRequestIndicators(true);
        }
      } catch (error) {
        SimpleAlert('Falta info', 3000, 'error');
      }
    };

    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);

  return { dataIndicators, loadingRequestIndicators };
};
