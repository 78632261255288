import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../../_mexablock/helpers';
import { viewPDF } from '../../../../service/operacion/Cuentas';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  archivo: string;
};

const ComprobanteModal: React.FC<Props> = ({ show, handleClose, archivo }) => {
  const [urlComprobante, setUrlComprobante] = useState<string>('');
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await viewPDF(archivo);
        if (res) setUrlComprobante(res);
      } catch (err) {
        console.error(err);
      }
    };

    if (show && archivo) {
      fetchPost();
    }
  }, [show, archivo]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      //   onHide={() => {
      //     formCallCenter.resetForm();
      //     handleClose(false);
      //   }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              handleClose(true);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="mb-13">
            <h1 className="mb-3"> Comprobante </h1>
          </div>

          <div className="align-items-center p-5 mb-10">
            <iframe
              src={`${urlComprobante}#navpanes=0`}
              width="100%"
              height="500px"
              style={{ border: 'none' }}
            />
          </div>
          <div className="text-center pt-10">
            <button
              className="btn btn-primary me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(true);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { ComprobanteModal };
