import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../_mexablock/helpers';
import { LineTime } from '../../../../_mexablock/helpers/components/LineTime';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  note: any;
};

const StatusCommentsModal: React.FC<Props> = ({ show, handleClose, note }) => {
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-md"
      show={show}
      //   onHide={() => {
      //     formCallCenter.resetForm();
      //     handleClose(false);
      //   }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 d-flex justify-content-center">
          <div className="row w-100 mb-10 ">
            <div className="col">
              <div className="mb-13">
                <h1 className="m-0"> Comentarios </h1>
              </div>
            </div>
            <div className="col text-end">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleClose(true);
                }}
              >
                <KTSVG
                  className="svg-icon-2"
                  path="/media/icons/duotune/arrows/arr061.svg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          {note && note.comments.length > 0 ? (
            <LineTime infoTimeLine={note} />
          ) : (
            <div className="text-muted">Sin cambio de estatus</div>
          )}

          <div className="text-center pt-10">
            <button
              className="btn btn-primary me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(true);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { StatusCommentsModal };
