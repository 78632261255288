import React, { useState, useEffect, useMemo } from 'react';
import { SaldosEmpresas } from './SaldosEmpresas';
import { GraficaTransacciones } from './GraficaTransacciones';
// import { AsignarSaldo } from './AsignarSaldo';
import { BannerSaldo } from './BannerSaldo';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../../_mexablock/helpers';
import { useSelectCuentas } from '../hooks/DashboardHook';
import { TopUpBalanceModal } from '../../../../_mexablock/partials/modals/balance/TopUpBalanceModal';
import { Offers } from './Offers';
import { useSelectOffers } from '../../operacion/activacion/hooks/ActivacionHook';
import { Carousel } from 'react-bootstrap-v5';

// import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils';

const DashboardPage: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  const [rol] = useState(userRoles ? userRoles[0].code : '');
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [reloadGrid] = useState(Math.random() * 40);
  const { data, count, loadingRequest } = useSelectCuentas(reloadGrid);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [dataCharts, setDataCharts] = useState([]);
  const [dataColors, setDataColors] = useState([]);
  const [activeTab, setActiveTab] = useState('todo');
  const [show, setShow] = useState(false);
  const { data: offers } = useSelectOffers(true, 'recharge');
  // const slides = [];
  const itemsPerSlide = 3;

  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingGrid(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    if (count > 0) {
      const dataEffect: any = [];
      const colorsEffect: any = [];
      data.forEach((element: any) => {
        const colors = getRandomHexColor();
        colorsEffect.push(colors);
        dataEffect.push({
          name: element.distributor
            ? element.distributor.first_name
            : element.company.name,
          color: colors,
          data: [
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
            getRandomNumber(),
          ],
        });
      });
      setDataCharts(dataEffect);
      setDataColors(colorsEffect);
      setShow(true);
    }
  }, [count, data]);

  const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const getRandomNumber = () => {
    return Math.floor(Math.random() * 10000);
  };
  const filteredOffers = useMemo(() => {
    return offers.filter((offer: any) => {
      if (activeTab === 'semanal') return offer.days === 7;
      if (activeTab === 'mensual') return offer.days === 30;
      if (activeTab === 'anual') return offer.days === 365;
      return true;
    });
  }, [offers, activeTab]);

  const slides = useMemo(() => {
    const itemsPerSlide = 3;
    const result = [];
    for (let i = 0; i < filteredOffers.length; i += itemsPerSlide) {
      result.push(filteredOffers.slice(i, i + itemsPerSlide));
    }
    return result;
  }, [filteredOffers]);

  return (
    <>
      <div className="row g-6 g-xl-9">
        <h2>MIS PLANES</h2>
        <div className="d-flex justify-content-center align-items-center">
          <ul className="nav">
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  activeTab === 'todo' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('todo')}
              >
                Todo
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  activeTab === 'semanal' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('semanal')}
              >
                Semanal
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                  activeTab === 'mensual' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('mensual')}
              >
                Mensual
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${
                  activeTab === 'anual' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('anual')}
              >
                Anual
              </a>
            </li>
          </ul>
        </div>
        <Carousel
          controls={true}
          indicators={true}
          interval={4000}
          className="carousel-dark"
          key={activeTab}
        >
          {slides.map((slide, index) => (
            <Carousel.Item key={index}>
              <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3 justify-content-center">
                {slide.map((item: any) => (
                  <div key={item.id} className="col">
                    <Offers data={item} />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) &&
          dataCharts.length > 0 && (
            <div className="col-xl-8">
              <GraficaTransacciones
                series={dataCharts}
                colors={dataColors}
                show={show}
                height={350}
                className="card card-flush h-xl-100"
              />
            </div>
          )}
        {/* {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) && (
          <div className="col-xl-4">
            <AsignarSaldo
              data={data}
              tipoRole={rol}
              callback={(updateData: boolean) => {
                if (updateData) {
                  setReloadGrid(Math.random() * 40);
                }
              }}
            />
          </div>
        )} */}
        {userHasRole(
          ['ADMIN_COMPANY', 'ADMIN_PARTNER', 'DISTRIBUTOR'],
          userRoles
        ) && (
          <div className="col-xl-4">
            <BannerSaldo
              data={{}}
              callback={() => {
                setVisivilityModal(!visivilityModal);
              }}
            />
          </div>
        )}
        {userHasRole(['ADMIN_MEXABLOCK', 'ADMIN_PARTNER'], userRoles) && (
          <div className="col-xl-4">
            <SaldosEmpresas
              data={data}
              loadingGrid={loadingGrid}
              tipoRole={rol}
            />
          </div>
        )}
      </div>
      <TopUpBalanceModal
        show={visivilityModal}
        handleClose={(updateData: boolean) => {
          setVisivilityModal(!visivilityModal);
        }}
      />
    </>
  );
};

export { DashboardPage };
