import { useState, useEffect } from 'react';
import { getAllCuentas } from '../../../../service/operacion/Cuentas';
import { CuentaModel } from '../models/CuentaModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllCuentas(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid, filters]);
  return { data, loadingRequest };
};

// export const useGetOne = (id: number) => {
//   const initModel = {
//     id: 0,
//     name: '',
//     business_name: '',
//     manager: {
//       id: 0,
//       alias: "",
//       first_name: "",
//       father_last_name: "",
//       mother_last_name: "",
//       email: "",
//       phone: "",
//       curp: "",
//       rfc: "",
//       birthdate: new Date(),
//       active: false,
//       gender: {
//         id: 1,
//         code: 'MAS',
//         name: 'Masculino'
//       },
//       state: {
//         id: 0,
//         code: 'SEL',
//         name: 'Seleccione'
//       },
//       municipality: {
//         id: 0,
//         code: 'SEL',
//         name: 'Seleccione'
//       },
//       neighborhood: {
//         id: 0,
//         code: 'SEL',
//         name: 'Seleccione'
//       },
//       postal_code: ''
//     }
//   };
//   const [initValues, setInitValues] = useState<CuentaModel>(initModel);
//   const history = useHistory();
//   useEffect(() => {
//     const fetchPost = async () => {
//       const res = await getOneEmpresas(id)
//         .then()
//         .catch((error) => {
//           history.push('/error/500');
//         });
//       if (res) {
//         setInitValues(res);
//       }
//     };
//     if (id) {
//       fetchPost();
//     } else {
//       setInitValues(initModel);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [id]);
//   return { initValues };
// };
