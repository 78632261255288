/* eslint-disable react-hooks/rules-of-hooks */

export const formatDate = (date: string) => {
  return new Date(date).toISOString().split('T')[0];
};
export const formatPrice = (
  price: number,
  locale: string,
  currency: string,
  minDigits: number,
  maxDigits: number
) => {
  return Number(price).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
};
export const maskPhoneNumber = (inputPhoneNumber: string): string => {
  const numericValue = inputPhoneNumber.replace(/[^\d]/g, '');
  let maskedValue = '';
  for (let i = 0; i < numericValue.length && i < 10; i++) {
    if (i === 0) maskedValue += `${numericValue[i]}`;
    else if (i === 2) maskedValue += ` ${numericValue[i]}`;
    else if (i === 6) maskedValue += ` ${numericValue[i]}`;
    else maskedValue += numericValue[i];
  }
  return maskedValue;
};
export const cleanPhoneNumber = (phoneNumber: string): string => {
  const phone = phoneNumber.replace(/\D/g, '');
  return phone;
};

// Función para calcular dias entre fechas

export const countDaysBetweenDates = (
  dateInit: string,
  dateEnd: string
): number => {
  const startDate = new Date(dateInit);
  const endDate = new Date(dateEnd);

  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 3600 * 24)
  );

  return differenceInDays;
};

// Función para calcular porcentaje
/**
 * Calcular porcentaje disponible
 * @param {number} total - Valor en decimales a formatear .
 * @param {number} consumed - cantidad de decimales a mostrar 2 y 4.
 * @returns {number} numero formateado
 */
export const calculatePercentageAvailable = (
  total: number,
  consumed: number
): number => {
  if (total === 0) {
    return 0;
  }
  const available = total - consumed;
  const percentageAvailable = (available / total) * 100;
  return parseFloat(percentageAvailable.toFixed(2));
};

export const calculatePercentage = (total: number, part: number): number => {
  if (total === 0) {
    return 0;
  }
  const percentage = (part / total) * 100;
  return percentage;
};

export const calculateRemainingPercentage = (
  total: number,
  part: number
): number => {
  if (total === 0) {
    return 0;
  }
  const percentageUsed = (part / total) * 100;
  const percentageRemaining = 100 - percentageUsed; // Lo que queda del 100%
  return percentageRemaining;
};

export const formatNumber = (num: number) => {
  return Number.isInteger(num) ? num : num.toFixed(2);
};

export const formatDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

export const formatDateMonth = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDateCompleta = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return `${day} de ${month} ${year}`;
};

export const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${String(hours).padStart(2, '0')}:${minutes} ${period}`;
};

export const formatFullTime = (dateString: string) => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0'); // 24 horas
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};

// formato de telefono
export const formatPhoneNumber = (telefono: number) => {
  const cleaned = ('' + telefono).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }

  return null;
};
// años para tarjeta
export const getYearOptions = (): { value: number; label: number }[] => {
  const currentYear = new Date().getFullYear();
  const yearOptions = [];

  for (let i = 0; i <= 6; i++) {
    const year = currentYear + i;
    yearOptions.push({ value: year, label: year });
  }

  return yearOptions;
};
