import React, { useState, useEffect } from 'react';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { useGetAll } from '../hooks/SIMchange';
import { CreateModal } from '../_modals/CreateModal';
import {
  formatDateMonth,
  formatTime,
  formatPhoneNumber,
  KTSVG,
  LoadingGrid,
} from '../../../../_mexablock/helpers';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setVisivilityModal(!visivilityModal);
  };
  // Informacion de la tabla
  const columns: any = [
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => formatDateMonth(row.created_at),
      sortable: true,
      width: '110px',
    },
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Hora</div>,
      selector: (row: any) => formatTime(row.created_at),
      sortable: true,
      width: '100px',
    },
    {
      id: 'msisdn',
      name: <div className="fw-bolder text-muted me-5">MSISDN</div>,
      selector: (row: any) => formatPhoneNumber(row.msisdn),
      sortable: true,
      width: '120px',
    },
    {
      id: 'prev_iccid',
      name: <div className="fw-bolder text-muted me-5">ICCID Antigua</div>,
      selector: (row: any) => row.prev_iccid,
      sortable: true,
      width: '185px',
    },
    {
      id: 'new_iccid',
      name: <div className="fw-bolder text-muted me-5">ICCID Nueva</div>,
      selector: (row: any) => row.new_iccid,
      sortable: true,
      width: '185px',
    },

    {
      id: 'customer.first_name',
      name: <div className="fw-bolder text-muted me-5">Cliente</div>,
      selector: (row: any) =>
        row.customer
          ? `${row.customer?.first_name} ${row.customer?.father_last_name} ${row.customer?.mother_last_name}`
          : 'Sin cliente',
      sortable: true,
      width: '200px',
    },
    {
      id: 'customer.creator.roles',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) =>
        row.creator ? row.creator?.roles[0].name : 'Sin rol',
      sortable: true,
      width: '130px',
    },
    {
      id: 'customer.creator',
      name: <div className="fw-bolder text-muted me-5">Usuario</div>,
      selector: (row: any) =>
        row.creator
          ? `${row.creator?.first_name} ${row.creator?.father_last_name} ${row.creator?.mother_last_name}`
          : 'Sin usuario',

      sortable: true,
      width: '200px',
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              {/* <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              /> */}
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addRegister}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={0}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
    </>
  );
};

export { View };
