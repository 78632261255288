import React, { useState, useEffect } from 'react';
import { LoadingGrid, formatDateMonth } from '../../../../_mexablock/helpers';
import { DetailsModal } from '../_modals/DetalleEnvios';
import { EstatusModal } from '../_modals/CambioEstatus';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { getAllEnvios } from '../../../service/envios/envios';
import { StatusCommentsModal } from '../_modals/EstatusComentarios.tsx';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import useSubscription from '../../../../hooks/useSubscription';
import { MenuComponent } from '../../../../_mexablock/assets/ts/components';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityModalComments, setVisivilityModalComments] = useState(false);
  const [visivilityModalComprobante, setVisivilityModalComprobante] =
    useState(false);
  const [voucherModal, setVoucherModal] = useState('');
  const [statusCommentsModal, setStatusCommentsModal] = useState();
  const [commetsModal, setcommetsModal] = useState({});
  const [dataSends, setDataSends] = useState<any[]>([]);
  const [dateState, setDateState] = useState<Date[]>([]);
  const [statusFilter, setStatusFilter] = useState<number>(0);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [key, setKey] = useState(0);

  const fetchPost = async () => {
    try {
      const formatDate = (date: Date | undefined) => {
        return date ? date.toISOString().split('T')[0] : undefined;
      };
      const filters = {
        search: searchFilter,
        status_id: statusFilter,
        start_date: formatDate(dateState[0]),
        end_date: formatDate(dateState[1]),
      };
      const sends = await getAllEnvios(filters);
      if (sends) {
        setDataSends(sends);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloadingView(false);
    }
  };
  useEffect(() => {
    MenuComponent.reinitialization();
  }, [dataSends]);

  useEffect(() => {
    fetchPost();
  }, [dateState, statusFilter, searchFilter]);

  const infoStatusComments = (statusinfo: any) => {
    setStatusCommentsModal(statusinfo);
    setVisivilityModal(true);
  };

  const infoDetails = (comment: any) => {
    setcommetsModal(comment);
    setVisivilityModalComments(true);
  };

  const infoComprobante = (voucher: any) => {
    setVoucherModal(voucher);
    setVisivilityModalComprobante(true);
  };

  const columns: any = [
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => row.created_at,
      cell: (row: any) =>
        row.created_at ? formatDateMonth(row.created_at) : 'Sin fecha',

      sortable: true,
    },
    {
      id: 'cash',
      name: <div className="fw-bolder text-muted me-5">ID de envio</div>,
      selector: (row: any) => row.id,
      sortable: true,
    },

    {
      id: 'user',
      name: <div className="fw-bolder text-muted me-5">Nombre del cliente</div>,
      selector: (row: any) => row.user.first_name,
      cell: (row: any) => (
        <div>
          {row.user.first_name} {row.user.father_last_name}
        </div>
      ),
      sortable: true,
    },

    {
      id: 'status',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.status.name,
      cell: (row: any) => statusEnvio(row),
      sortable: true,
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
    },
  ];

  const statusEnvio = (row: any) => {
    switch (row.status.id) {
      case 1:
        return (
          <span className="fw-normal badge badge-light-warning text-center d-inline-block">
            Pendiente
          </span>
        );
      case 2:
        return (
          <span className="fw-normal badge badge-light-primary text-center d-inline-block">
            En proceso
          </span>
        );
      case 3:
        return (
          <span className="fw-normal badge badge-light-success text-center d-inline-block">
            Exitoso
          </span>
        );

      case 4:
        return (
          <span className="fw-normal badge badge-light-danger text-center d-inline-block">
            Error
          </span>
        );
    }
  };

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  infoDetails(row);
                }}
              >
                Ver detalles
              </a>
            </div>
            {row.status.name === 'Exitoso' ? null : (
              <div className="menu-item px-3">
                <a
                  className="menu-link px-3"
                  href="#/"
                  onClick={() => {
                    infoComprobante(row);
                  }}
                >
                  Cambiar estatus
                </a>
              </div>
            )}
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  infoStatusComments(row);
                }}
              >
                Ver comentarios
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleWebSocketMessage = (message: any) => {
    console.log('Mensaje recibido del WebSocket:', message);

    if (message) {
      setDataSends((prevData) => {
        const exists = prevData.some((item) => item.id === message.id);

        if (exists) {
          return prevData.map((item) =>
            item.id === message.id ? message : item
          );
        }

        return [message, ...prevData];
      });
    }
  };

  const handleWebSocketError = (error: any) => {
    console.error('Error del WebSocket:', error);
  };

  useSubscription('new-sim-shipments', {
    onMessage: handleWebSocketMessage,
    onError: handleWebSocketError,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[a-zA-Z0-9\sáéíóúüñÁÉÍÓÚÜÑ]*$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchFilter(inputValue);
    }
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="row w-100">
                <div className="col-4">
                  <div className="input-group">
                    <span className="input-group-text bg-white border-end-0">
                      <i className="bi bi-calendar-event"></i>
                    </span>
                    <Flatpickr
                      key={key}
                      value={dateState}
                      onChange={(selectedDates) => {
                        setDateState(
                          selectedDates.filter(
                            (date) => date instanceof Date
                          ) as Date[]
                        );
                      }}
                      options={{
                        mode: 'range',
                        dateFormat: 'd-M',
                        locale: {
                          ...Spanish,
                          rangeSeparator: ' a ',
                        },
                      }}
                      className="form-control border-start-0"
                      placeholder="Selecciona fecha"
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        setDateState([]);
                        setKey((prevKey) => prevKey + 1);
                      }}
                    >
                      Limpiar
                    </button>
                  </div>
                </div>
                <div className="col-5">
                  <ul className="nav justify-content-center align-items-center w-100">
                    <li className="nav-item">
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                          statusFilter === 0 ? 'active' : ''
                        }`}
                        onClick={() => setStatusFilter(0)}
                      >
                        Todos
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                          statusFilter === 1 ? 'active' : ''
                        }`}
                        onClick={() => setStatusFilter(1)}
                      >
                        Pendiente
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                          statusFilter === 2 ? 'active' : ''
                        }`}
                        onClick={() => setStatusFilter(2)}
                      >
                        En proceso
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                          statusFilter === 3 ? 'active' : ''
                        }`}
                        onClick={() => setStatusFilter(3)}
                      >
                        Exitoso
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${
                          statusFilter === 4 ? 'active' : ''
                        }`}
                        onClick={() => setStatusFilter(4)}
                      >
                        Error
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-3">
                  <div className="d-flex align-items-center position-relative me-4">
                    <span
                      className="position-absolute ms-3"
                      style={{ zIndex: 10 }}
                    >
                      <i className="bi bi-search fs-4 text-muted"></i>
                    </span>

                    <input
                      type="text"
                      className="form-control form-control-solid ps-9"
                      placeholder="Buscar"
                      maxLength={200}
                      onKeyDown={handleKeyDown}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={dataSends} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>

      <StatusCommentsModal
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setVisivilityModal(!visivilityModal);
            fetchPost();
          }
        }}
        note={statusCommentsModal}
      />

      <DetailsModal
        show={visivilityModalComments}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setVisivilityModalComments(!visivilityModalComments);
            fetchPost();
          }
        }}
        infoEnvios={commetsModal}
      />
      <EstatusModal
        show={visivilityModalComprobante}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setVisivilityModalComprobante(!visivilityModalComprobante);
            fetchPost();
          }
        }}
        infoStatus={voucherModal}
      />
    </>
  );
};

export { View };
