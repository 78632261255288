import axios from 'axios';
import { toAbsoluteUrl, formatPrice } from '../../../../_mexablock/helpers';
import { AuthenticationModel } from '../models/AuthenticationModel';

const AUTH_URL = process.env.REACT_APP_API;
const API_URL_IMAGE_PUBLIC = `${process.env.REACT_APP_PUBLIC}users/`;

export function login(email: string, password: string) {
  const response = axios.post<AuthenticationModel>(`${AUTH_URL}login`, {
    email,
    password,
  });
  return response;
}

export async function requestPassword(email: string) {
  return await axios.post(`${AUTH_URL}recover`, { email });
}
export async function restorePassword(
  password: string,
  confirmPassword: string,
  token: string
) {
  return await axios.post<AuthenticationModel>(`${AUTH_URL}reset/${token}`, {
    password,
    confirm_password: confirmPassword,
  });
}
export async function getUserByToken() {
  const response = await axios.get(`${AUTH_URL}users/me`);
  const {
    data: {
      doc: {
        data: { virtual_balance },
      },
    },
  } = await axios.get(`${AUTH_URL}catalogos/account/me`);
  const random = Math.random() * 40;
  const {
    doc: {
      data: {
        id,
        first_name,
        father_last_name,
        mother_last_name,
        alias,
        email,
        birthdate,
        phone,
        roles,
        profile_photo,
        active,
        gender,
        company,
        account,
      },
    },
  } = response.data;

  return {
    id: id,
    first_name: first_name,
    father_last_name: father_last_name,
    mother_last_name: mother_last_name,
    alias: alias,
    email: email,
    phone: phone,
    company:
      roles[0].code !== 'DISTRIBUTOR'
        ? company.name
        : `${first_name} ${father_last_name}`,
    birthdate: birthdate,
    gender: gender,
    roles: roles,
    active: active,
    saldo: Number(virtual_balance),
    saldo_format: formatPrice(Number(virtual_balance), 'es-MX', 'MXN', 2, 2),
    profile_photo: profile_photo
      ? `${API_URL_IMAGE_PUBLIC}${profile_photo}?${random}`
      : toAbsoluteUrl('/media/avatars/blank.png'),
    account: account,
  };
}
