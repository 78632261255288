/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { formatPrice, KTSVG } from '../../../../_mexablock/helpers';

type Props = {
  data: any;
};

const Offers: React.FC<Props> = ({ data }) => {
  return (
    <>
      <div className="card card card-flush h-xl-100 border border-3">
        <div className="card-body">
          <h2 style={{ color: '#F68626' }}>{`${data.name}`}</h2>
          <div>
            <span className="fs-5 fw-bolder d-block text-hover-primary">
              {`${data.gb} GB de Datos`}
            </span>
          </div>
          <span className="fw-semibold fs-6">
            {`${formatPrice(data.price, 'es-MX', 'MXN', 2, 2)}`}
          </span>
          <span className="fw-semibold fs-6">
            {` / ${data.commercial_time} días`}
          </span>
          <div className="separator separator-dashed mb-5 mt-5" />

          <div className="fw-semibold fs-5 mt-1 text-gray-600">
            {data.id !== 38 && data.id !== 8 ? (
              <i
                className="fas fa-thumbs-up me-3"
                style={{ color: '#F68626' }}
              />
            ) : (
              <i className="fas fa-thumbs-up me-3" />
            )}
            Redes ilimitadas
          </div>
          <div className="fw-semibold fs-5 mt-1 text-gray-600">
            {data.share_internet ? (
              <i className="fas fa-wifi me-3" style={{ color: '#F68626' }} />
            ) : (
              <i className="fas fa-wifi me-3" />
            )}
            Hotspot
          </div>
          <div className=" fw-semibold fs-5  mt-1 text-gray-600">
            <i className="fas fa-phone-alt me-3" style={{ color: '#F68626' }} />
            {`${data.minutes} Minutos`}
          </div>
          <div className=" fw-semibold fs-5 mt-1 text-gray-600">
            <i className="fas fa-sms me-3" style={{ color: '#F68626' }} />
            {`${data.sms} SMS`}
          </div>
        </div>
      </div>
    </>
  );
};

export { Offers };
