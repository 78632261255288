import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllCuentas(filters: any) {
  const response = await axios.get(`${API_URL}catalogos/provision`);

  const data = response.data.doc.data;
  return data;
}

export async function getOneCuentas(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
  const {
    company,
    id,
    alias,
    first_name,
    father_last_name,
    mother_last_name,
    email,
    phone,
    curp,
    rfc,
    birthdate,
    active,
    gender,
    postal_code,
  } = response.data.doc.data;
  return {
    id: company.id || 0,
    name: company.name || '',
    business_name: company.business_name || '',
    manager: {
      id: id || 0,
      alias: alias || '',
      first_name: first_name || '',
      father_last_name: father_last_name || '',
      mother_last_name: mother_last_name || '',
      email: email || '',
      phone: phone || '',
      curp: curp || '',
      rfc: rfc || '',
      birthdate: birthdate || new Date(),
      active: active || false,
      gender: {
        id: gender.id || 0,
        code: gender.code || '',
        name: gender.name || '',
      },
      postal_code: postal_code || '',
    },
  };
}

export async function provisionBalance(body: any) {
  const { new_balance, account_id } = body;
  const response = await axios.post(`${API_URL}catalogos/account/add`, {
    new_balance,
    account_id,
  });
  return response;
}
export async function topUpBalance(body: any) {
  const { new_balance, payment_id } = body;
  const response = await axios.post(`${API_URL}catalogos/balance-request`, {
    payment_method_id: payment_id,
    cash: new_balance,
  });
  return response;
}
export async function AddBalanceMexa(body: any) {
  const { balance, file } = body;

  let formData = new FormData();
  formData.append('ammount_sv', balance);
  formData.append('pdfFile', file);
  const response = await axios({
    method: 'post',
    url: `${API_URL}operation/comprar/saldo/playcell`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;

  // const response = await axios.post(`${API_URL}operation/comprar/saldo/playcell`, {
  //   ammount_sv: balance
  // });
  // return response;
}

export async function approvisionamiento(body: any) {
  const {
    companyId,
    distributorId,
    saldoVirtualAmount,
    saldoCashAmount,
    payType,
    pdfFile,
    comments,
    is_consignment,
  } = body;
  let formData = new FormData();
  companyId
    ? formData.append('companyId', companyId)
    : formData.append('distributorId', distributorId);
  formData.append('virtual', saldoVirtualAmount.toFixed(2));
  // formData.append('saldoCashAmount', saldoCashAmount.toFixed(2));
  formData.append('payment_type_id', payType);
  formData.append('pdfFile', pdfFile);
  formData.append('comments', comments);
  formData.append('is_consignment', is_consignment);
  const response = await axios({
    method: 'post',
    url: `${API_URL}operation/venta/saldo`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
}

export async function verifyBalance(saldoVirtualAmount: number) {
  const response = await axios.post(
    `${API_URL}catalogos/account/verify/balance`,
    {
      saldoVirtualAmount,
    }
  );
  return response;
}

export async function viewPDF(pdfRoute: string) {
  try {
    const response = await axios({
      url: `${API_URL}catalogos/provision/voucher?fileName=${pdfRoute}`,
      method: 'GET',
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    return url;
  } catch (error) {
    console.error('Error al visualizar el PDF:', error);
  }
}
