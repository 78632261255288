import React, { createContext, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

type WebSocketContextType = {
  ws: WebSocket | null;
  readyState: WebSocket['readyState'];
};

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

const WebSocketProvider: React.FC = ({ children }) => {
  const token = useSelector((state: any) => state.auth.accessToken);
  const [readyState, setReadyState] = useState<WebSocket['readyState']>(WebSocket.CLOSED);
  const wsRef = useRef<WebSocket | null>(null);

  const url = process.env.REACT_APP_WEB_SOCKET;

  useEffect(() => {
    if (!token) return;
    const connectWebSocket = () => {
      if (wsRef.current && wsRef.current.readyState !== WebSocket.CLOSING && wsRef.current.readyState !== WebSocket.CLOSED) {
        return;
      }

      const socket = new WebSocket(`${url}?token=${token}`);
      wsRef.current = socket;

      socket.onopen = () => {
        setReadyState(WebSocket.OPEN);
      };

      socket.onclose = () => {
        setReadyState(WebSocket.CLOSED);
      };

      socket.onerror = (error) => {
        setReadyState(WebSocket.CLOSED);
        console.error('WebSocket error:', error);
      };
    };

    connectWebSocket();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [token]);

  const value = useMemo(() => ({
    ws: wsRef.current,
    readyState,
  }), [wsRef.current, readyState]);

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export { WebSocketProvider, useWebSocket };
