import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function AddNumIccid(body: any) {
  const { iccid, id } = body;

  const response = await axios.patch(
    `${API_URL}operation/sim-shipments/${id}/iccid`,
    {
      iccid: iccid,
    }
  );

  return response.data;
}

export async function AddCommentICCID(body: any) {
  console.log('info pre envio: ', body);
  const { status_id, comment, id } = body;
  const response = await axios.post(
    `${API_URL}operation/sim-shipments/${id}/comment/activation`,
    {
      status_id: status_id,
      comment: comment,
    }
  );

  return response.data;
}
