import React, { useState, useRef } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
  toAbsoluteUrl,
} from '../../../../_mexablock/helpers';
import { NumericFormat } from 'react-number-format';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  addDistribuidor,
  // updateEmpresas,
} from '../../../service/distribuidores/Distribuidores';
import { useGetOne } from '../hooks/DistribuidorHook';
import { useSelectGeneric } from '../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const DistribuidorSchema = Yup.object().shape({
  // type: Yup.object().shape({
  //   id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  // }),
  account: Yup.object().shape({
    typeCommission: Yup.object().shape({
      id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
    }),
  }),
  name: Yup.string().when('type.code', {
    is: 'EMP',
    then: Yup.string().max(100, 'Máximo 200 caracteres').required('Requerido.'),
    otherwise: Yup.string(),
  }),
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Requerido.'),
  business_name: Yup.string()
    .max(100, 'Máximo 200 caracteres')
    .required('Requerido.'),
  cfdi: Yup.object().shape({
    id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
  file: Yup.mixed()
    .required('Requerido.')
    .test('is-pdf', 'El archivo debe ser un PDF', (value) => {
      if (value) {
        return value.type === 'application/pdf';
      }
      return false;
    }),
  rfc: Yup.string()
    .required('Requerido.')
    .matches(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      'Formato de RFC inválido'
    ),
  clabe: Yup.string().min(18, 'Mínimo 18 caracteres').required('Requerido.'),
  user: Yup.object().shape({
    first_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),

    mother_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    father_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    birthdate: Yup.date().required('Requerido.'),
    email: Yup.string()
      .email('Formato inválido.')
      .min(3, 'Mínimo 3 caracteres.')
      .max(50, 'Máximo 50 caracteres.')
      .required('Requerido.'),
    phoneFormat: Yup.string()
      .required('Requerido.')
      .min(10, 'Formato inválido'),
    gender: Yup.object().shape({
      id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
    }),
  }),
});

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18); // Restar 18 años
const maxDate = fechaMaxima.toISOString().split('T')[0];

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);
  const constancyRef = useRef<HTMLInputElement>(null);
  const [documentName, setDocumentName] = useState<string>('');
  const { catalog: genders } = useSelectGeneric('GEN');
  const { catalog: cfdi } = useSelectGeneric('CFDI');
  const { catalog: tipo_comision } = useSelectGeneric('TDC');

  const formDistribuidor = useFormik({
    initialValues: initValues,
    validationSchema: DistribuidorSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          // updateEmpresas(values)
          //   .then((res) => {
          //     const {
          //       data: { message },
          //     } = res;
          //     SimpleAlert(message, 3000, 'success');
          //     formDistribuidor.resetForm();
          //     setLoading(false);
          //     handleClose(true);
          //   })
          //   .catch((err) => {
          //     let resMessageToast: string = '';
          //     const {
          //       data: { message, details = null },
          //     } = err.response;
          //     resMessageToast = `${message}`;
          //     if (details.length) {
          //       setStatus(`${details[0]?.message}`);
          //     }
          //     setLoading(false);
          //     setSubmitting(false);
          //     SimpleAlert(resMessageToast, 3000, 'error');
          //   });
        } else {
          addDistribuidor(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formDistribuidor.resetForm();
              setDocumentName('');
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formDistribuidor.setFieldValue('user.phone', maskedPhoneNumber);
    formDistribuidor.setFieldValue(
      'user.phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  const onOpenFile = () => {
    constancyRef?.current?.click();
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formDistribuidor.setFieldValue(key, inputLimpio);
  };

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        setDocumentName('');
        formDistribuidor.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              setDocumentName('');
              formDistribuidor.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Nuevo distribuidor</h1>
            <div className="text-muted fw-semibold fs-5">
              Agrega la información del distribuidor
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formDistribuidor.handleSubmit}
          >
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className={`fw-bold fs-6 mb-2 w-auto required`}>
                    Nombre
                  </label>
                  {formDistribuidor.touched.name &&
                    formDistribuidor.errors.name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{formDistribuidor.errors.name}</span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Nombre del distribuidor"
                  {...formDistribuidor.getFieldProps('name')}
                  className="form-control"
                  type="text"
                  name="name"
                  autoComplete="off"
                />
              </div>

              <div className="col-md-4">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Razón social
                  </label>
                  {formDistribuidor.touched.business_name &&
                    formDistribuidor.errors.business_name && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.business_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Razón social"
                  {...formDistribuidor.getFieldProps('business_name')}
                  className="form-control"
                  type="text"
                  name="business_name"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-4">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Comisión
                  </label>
                  {formDistribuidor.touched.account?.typeCommission?.id &&
                    formDistribuidor.errors.account?.typeCommission?.id && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.account.typeCommission.id}
                        </span>
                      </div>
                    )}
                </div>
                <select
                  className="form-select"
                  {...formDistribuidor.getFieldProps(
                    'account.typeCommission.id'
                  )}
                >
                  <option value={0}>Seleccione</option>
                  {tipo_comision.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-gray-600 mt-7">Datos del encargado</div>
            <div className="separator my-5"></div>
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Nombre
                  </label>
                  {formDistribuidor.touched.user?.first_name &&
                    formDistribuidor.errors.user?.first_name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.first_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Nombre del encargado"
                  {...formDistribuidor.getFieldProps('user.first_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="user.first_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'user.first_name');
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Paterno
                  </label>
                  {formDistribuidor.touched.user?.father_last_name &&
                    formDistribuidor.errors.user?.father_last_name && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.father_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Paterno"
                  {...formDistribuidor.getFieldProps('user.father_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="user.father_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'user.father_last_name');
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Materno
                  </label>
                  {formDistribuidor.touched.user?.mother_last_name &&
                    formDistribuidor.errors.user?.mother_last_name && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.mother_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Apellido Materno"
                  {...formDistribuidor.getFieldProps('user.mother_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="user.mother_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'user.mother_last_name');
                  }}
                />
              </div>
            </div>
            <div className="row g-9 mb-5">
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Genero
                  </label>
                  {formDistribuidor.touched.user?.gender?.id &&
                    formDistribuidor.errors.user?.gender?.id && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.gender.id}
                        </span>
                      </div>
                    )}
                </div>
                <select
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formDistribuidor.getFieldProps('user.gender.id')}
                >
                  <option value={0}>Seleccione</option>
                  {genders.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Fecha de nacimiento
                  </label>
                  {formDistribuidor.touched.user?.birthdate &&
                    formDistribuidor.errors.user?.birthdate && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.birthdate}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  type="date"
                  className="form-control"
                  onKeyDown={(e) => e.preventDefault()}
                  value={
                    new Date(formDistribuidor.values.user.birthdate)
                      .toISOString()
                      .split('T')[0]
                  }
                  max={maxDate}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formDistribuidor.setFieldValue('user.birthdate', fecha);
                    }
                  }}
                ></input>
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Correo
                  </label>
                  {formDistribuidor.touched.user?.email &&
                    formDistribuidor.errors.user?.email && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.email}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Correo"
                  {...formDistribuidor.getFieldProps('user.email')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="user.email"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Teléfono
                  </label>
                  {formDistribuidor.touched.user?.phoneFormat &&
                    formDistribuidor.errors.user?.phoneFormat && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.user.phoneFormat}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  className="form-control mb-3"
                  value={formDistribuidor.values.user.phone}
                  placeholder="Teléfono"
                  prefix=""
                  onChange={handlePhoneChange}
                />
              </div>

              {/* <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-40">CURP</label>
                  {formDistribuidor.touched.user?.curp &&
                    formDistribuidor.errors.user?.curp && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">{formDistribuidor.errors.user.curp}</span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="CURP"
                  {...formDistribuidor.getFieldProps('user.curp')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="user.curp"
                  autoComplete="off"
                />
              </div> */}
            </div>
            <div className="row g-9 mb-5"></div>
            <div className="text-gray-600 mt-7">Datos de facturación</div>
            <div className="separator my-5"></div>
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Uso de CFDI
                  </label>
                  {formDistribuidor.touched.cfdi?.id &&
                    formDistribuidor.errors.cfdi?.id && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.cfdi.id}
                        </span>
                      </div>
                    )}
                </div>
                <select
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formDistribuidor.getFieldProps('cfdi.id')}
                >
                  <option value={0}>Seleccione</option>
                  {cfdi.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Correo
                  </label>
                  {formDistribuidor.touched.email &&
                    formDistribuidor.errors.email && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.email}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Correo"
                  {...formDistribuidor.getFieldProps('email')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="email"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-auto required">
                    RFC
                  </label>
                  {formDistribuidor.touched.rfc && formDistribuidor.errors.rfc && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formDistribuidor.errors.rfc}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="RFC"
                  {...formDistribuidor.getFieldProps('rfc')}
                  className="form-control mb-3 mb-lg-0"
                  maxLength={13}
                  type="text"
                  name="rfc"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row g-9">
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    CLABE Interbancaria
                  </label>
                  {formDistribuidor.touched.clabe &&
                    formDistribuidor.errors.clabe && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formDistribuidor.errors.clabe}
                        </span>
                      </div>
                    )}
                </div>
                <NumericFormat
                  name="clabe"
                  className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                  value={formDistribuidor.values.clabe}
                  decimalScale={0}
                  allowLeadingZeros
                  maxLength={18}
                  fixedDecimalScale
                  placeholder="CLABE interbancaria"
                  prefix=""
                  thousandSeparator=""
                  onValueChange={(values) => {
                    const { value } = values;
                    formDistribuidor.setFieldValue('clabe', value ? value : '');
                  }}
                />
              </div>
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <div className="d-flex justify-content-center text-center p-2">
                  <a
                    href="#/"
                    className="text-gray-800 text-hover-primary d-flex flex-column"
                  >
                    <div className="symbol symbol-50px mb-6">
                      <img
                        src={
                          formDistribuidor.values.file ||
                          formDistribuidor.values.constancy
                            ? toAbsoluteUrl('/media/svg/files/pdf.svg')
                            : toAbsoluteUrl('/media/svg/files/upload.svg')
                        }
                        className="image-input-wrapper"
                        onClick={onOpenFile}
                        crossOrigin="anonymous"
                        alt="INE"
                      />
                    </div>

                    <div className="d-flex">
                      <div
                        className="required fs-5 mb-2 flex-column"
                        onClick={onOpenFile}
                      >
                        Constancia de situación fiscal
                      </div>
                      <div className="fs-5 mb-2 flex-column ms-5">
                        {formDistribuidor.touched.file &&
                          formDistribuidor.errors.file && (
                            <div className="fv-plugins-message-container text-danger w-100">
                              <span role="alert">
                                {formDistribuidor.errors.file}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <label className="min-h-30px text-success">
                      {documentName}
                    </label>

                    <input
                      style={{ display: 'none' }}
                      ref={constancyRef}
                      type="file"
                      name="constancy"
                      accept=".pdf"
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          let files = event.currentTarget.files;
                          if (files.length > 0) {
                            formDistribuidor.setFieldValue('file', files[0]);
                            setDocumentName(files[0].name);
                          }
                        }
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  setDocumentName('');
                  formDistribuidor.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
