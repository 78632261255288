import clsx from 'clsx';
import React, { FC, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HeaderUserMenu } from '../../../partials';
import { TopUpBalanceModal } from '../../../partials/modals/balance/TopUpBalanceModal';
// import {useLayout} from '../../core'
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import { userHasRole } from '../../../helpers';
import { useGetModeApp } from './hooks/HeaderHook';
import { updateParamTestMode } from '../../../../app/service/header/Header';
import { formatPrice } from '../../../helpers';

type Props = {
  changeHeaderColor: any;
};

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: React.FC<Props> = ({ changeHeaderColor }) => {
  const [visivilityModal, setVisivilityModal] = useState(false);
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const { isEnable } = useGetModeApp();
  const [enable, setEnable] = useState<boolean>(false);
  const [mode, setMode] = useState<boolean>(false);

  const changeBackGroundColor = (color: string) => {
    changeHeaderColor(color);
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_AMBIENTE === 'DEV' ||
      process.env.REACT_APP_AMBIENTE === 'QA' ||
      process.env.REACT_APP_AMBIENTE === 'PRE'
    ) {
      setEnable(isEnable !== undefined ? isEnable : false);
      if (isEnable) {
        changeBackGroundColor('#BFC9CA');
      } else {
        changeBackGroundColor('#FFFFFF');
      }
    } else {
      setMode(true);
      changeBackGroundColor('#FFFFFF');
      updateParamTestMode('TMOD', false)
        .then((res) => {
          const {
            data: { message },
          } = res;
          // changeBackGroundColor('#2ECC71')
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message, details = null },
          } = err.response;
          resMessageToast = `${message}`;
          if (details.length) {
            resMessageToast = `${details[0].message}`;
          }
        });
    }
  }, [isEnable]);

  useEffect(() => {
    if (enable) {
      changeBackGroundColor('#BFC9CA');
    } else {
      changeBackGroundColor('#FFFFFF');
    }
  }, [enable]);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="d-flex align-items-center">
        {/* {userHasRole(
          ['ADMIN_COMPANY', 'ADMIN_PARTNER', 'DISTRIBUTOR'],
          userRoles
        ) && (
          <a
            href="#/"
            className="btn btn-sm btn-primary fw-bold"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_upgrade_plan"
            onClick={() => {
              setVisivilityModal(!visivilityModal);
            }}
          >
            Recargar saldo
          </a>
        )} */}
        {/* {userHasRole(['ADMIN'], userRoles) && (
          <span className="fs-6 text-gray-600 fw-bold d-md-block">
            Saldo Playcell:
            <span className="text-gray-800"> {user.saldo_playcell_format}</span>
          </span>
        )} */}
        {!mode ? (
          <span className="fs-6 text-gray-600 fw-bold pe-3 d-md-block ms-5">
            <div className="form-check form-switch">
              <label className="form-check-label">Modo prueba</label>
              <input
                className="form-check-input"
                disabled={process.env.REACT_APP_AMBIENTE === 'PRE'}
                type="checkbox"
                checked={enable}
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={(e) => {
                  setEnable(e.target.checked);

                  updateParamTestMode('TMOD', e.target.checked)
                    .then((res) => {
                      const {
                        data: { message },
                      } = res;
                      // changeBackGroundColor('#2ECC71')
                    })
                    .catch((err) => {
                      let resMessageToast: string = '';
                      const {
                        data: { message, details = null },
                      } = err.response;
                      resMessageToast = `${message}`;
                      if (details.length) {
                        resMessageToast = `${details[0].message}`;
                      }
                    });
                }}
              />
            </div>
          </span>
        ) : null}
        {/* <h2 className='text-primary'>SOFTCELL</h2> */}
        {/* <div className="d-flex flex-column">
          {userHasRole(
            [
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'DISTRIBUTOR',
              'EMP_PARTNER',
              'EMP_COMPANY',
            ],
            userRoles
          ) && (
            <span className="fs-6 text-gray-600 fw-bold pe-3 d-md-block ms-5">
              Saldo:
              <span className="text-gray-800">
                {' '}
                {Number(user.account?.virtual_balance) > 0
                  ? formatPrice(
                      Number(user.account?.virtual_balance),
                      'es-MX',
                      'MXN',
                      2,
                      2
                    )
                  : 'Sin saldo'}
              </span>
            </span>
          )}
          {userHasRole(
            ['ADMIN_COMPANY', 'ADMIN_PARTNER', 'EMP_PARTNER', 'EMP_COMPANY'],
            userRoles
          ) && (
            <span className="fs-6 text-gray-600 fw-bold pe-3 d-md-block ms-5">
              {Number(user.account?.consignment_balance) > 0
                ? 'Saldo a consignación:'
                : null}
              <span className="text-gray-800">
                {' '}
                {Number(user.account?.consignment_balance) > 0
                  ? formatPrice(
                      Number(user.account?.consignment_balance),
                      'es-MX',
                      'MXN',
                      2,
                      2
                    )
                  : null}
              </span>
            </span>
          )}
        </div> */}
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="hover"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img src={user.profile_photo} crossOrigin="anonymous" alt="ad" />
        </div>
        <HeaderUserMenu />
        <TopUpBalanceModal
          show={visivilityModal}
          handleClose={(updateData: boolean) => {
            setVisivilityModal(!visivilityModal);
          }}
        />
      </div>
    </div>
  );
};

export { Topbar };
